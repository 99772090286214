.bulkTaskNew_main{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #091945 !important;
    min-height:  calc(100vh - 98px);;
}

.mt_6rem{
    margin-top: 6.1rem;
}

.bulkTaskNew_card{
    border: 1px solid lightgray;
    min-height: calc(80vh - 98px);
    width: 60%;
}

.hrLine{
    border-bottom: 1px solid #8080804d;
}

