.extenstion-screen {
  padding-top: 65px;
  padding-bottom: 30px;
}

.extension-slider-screen {
  padding: 15px;
  background: #a8c0ff; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #3f2b96,
    #a8c0ff
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #3f2b96,
    #a8c0ff
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  width: 85%;
  margin: 0 auto;
}

.carousel-control-prev-icon {
  font-size: 50px;
}

.carousel-control-next-icon {
  font-size: 50px;
}

.extenstionTitle {
  font-size: 45px;
  font-weight: 600;
  color: #1660be;
  text-align: left;
}

.chrome-extenstion-desc {
  width: 80%;
  margin: 0 auto 0 0;
}

.extenstion-points {
  list-style: none;
  padding: 0;
  margin: 0;
}

.extenstion-points li {
  line-height: 43px;
}

.btn-icon {
  font-size: 25px;
}

.browser-extenstion__rating--stars span {
  display: block;
  font-size: 15px;
  color: #4C555E;
}

.browser-extenstion__rating--stars .stars {
  color: #F6D028;
  font-size: 15px;
}