* {
  padding: 0px;
  margin: 0px;
  font-family: "Inter" !important ;
}

.txt_color {
  color: #E75A5A !important;
}
.subtxt_color{
  color: #848199 !important;
}

.bg_color {
  background-color: #E75A5A !important;
}

.bg_color_dark {
  background: #091945 !important;
}
.bg_color_dark2 {
  background: #13224F !important;
}

.navbar {
  border-radius: 0px;
  z-index: 99;
  
}
.navbar_linear_gradient{
  background: linear-gradient(270deg, rgba(255, 169, 140, 0.4) 4.64%, rgba(248, 154, 144, 0.4) 16.64%, rgba(228, 113, 156, 0.4) 41.64%, rgba(196, 48, 175, 0.4) 75.64%, rgba(173, 0, 189, 0.4) 98.64%);
}

.navcont {
  display: flex;
  justify-content: space-around;
}

.txtnav{
  font-family: "Inter" !important;
  font-weight: 500 !important;
  font-size: 0.775rem !important;
  color: #8A8787 !important;
}
.textnav{
  font-family: "outfit" !important;
  font-size: 1rem !important;
  color: #707070 !important;
}

.activeNav{
  color: #AD00BD !important;
  font-weight: 600 !important;
}

.txtnav:hover {
  color: #E75A5A !important;
}

.btn2 {
  width: 6rem;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  font-weight: 600;
  background-color: #091945 !important;
  border-bottom: 5px solid #E75A5A;
  border-radius: 0px;
  /* color: #E75A5A !important; */
  background: -webkit-linear-gradient(270deg, #FFA98C 4.64%, #F89A90 16.64%, #E4719C 41.64%, #C430AF 75.64%, #AD00BD 98.64%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.navbar-toggler{
  margin-left: 15px !important;
}

/* ---Body--- */

.bgimg {
  /* margin-top: -3rem; */
  background: url(/public/pic/bg_2.png) center center/cover no-repeat;
  /* position: absolute; */
  top: 0;
  width: calc(100% - 0px);
  height: 37rem;
  border-radius: 0px 0px 140px 140px;
}

.row {
  padding-inline: 0px !important;
}

/* .blueimg{
    width: -webkit-fill-available;
    z-index: 0;
} */

.texthead {
  top: 10rem;
}

.seepricing {
  vertical-align: middle;
}

.tryfree:hover {
  background-color: black !important;
}

.seepricing:hover {
  color: black !important ;
}

.pcimg {
  height: 20rem;
}

.videopc {
  /* position: absolute; */
  width: calc(15% - 0px);
  z-index: 99;
  /* margin-left: -25.7rem;
    margin-top: -2rem;  */
}

.image-container {
  position: absolute;
  /* top: 10rem; */
}

/* ---How It Works--- */

.howitwork {
  margin-top: 25rem;
  color: #E75A5A;
  scroll-margin-block-start: 7rem;
  scroll-behavior: smooth;
}

.workimg {
  width: calc(100% - 4rem);
}

.line_1 {
  margin-left: -4px;
}

.line_2 {
  margin-left: -4px;
}

/* ---Price--- */

.pricePlan{
  background: #13224F;
}
.pricePlan .mainTitle {
  font-size: 2.5rem;
  font-family: "outfit" !important;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 4rem;
}

.pricePlan .mainDescription {
  font-family: "outfit" !important;
  font-size: 1.3rem;
  font-weight: 500;
  color: #EBEBEB99;
  line-height: 2rem;
}
/* .pricePlan .priceCard {
  border-radius: 15px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 1px 9px 0px;
}
.pricePlan .priceCard:hover{
  background: #E75A5A;
} */
.pricePlan .btn-discove {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 28px;
  gap: 15px;
  background-color: transparent;
  outline: 3px #E75A5A solid;
  outline-offset: -3px;
  border-radius: 10px;
  border-color: #E75A5A;
  cursor: pointer;
  transition: 400ms;
  color: #E75A5A;
  font-family: "Inter";
  font-size: 1.2rem;
  font-weight: 600;
}

.pricePlan .priceCard:hover .btn-discove {
  background-color: #E75A5A;
  color: white;
  border: none;  
}

.workWithUsWeb .comparisonSection {
  
}
.workWithUsWeb .pricePlan .priceCard .title {
  font-family: "Inter";
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2rem;
  color: #191A15;
}


.workWithUsWeb .infoSection:hover .pricePlan .priceCard .title {
  color: #F9FAFB;
}

.workWithUsWeb .pricePlan .priceCard .priceText {
  font-family: "Inter";
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 2rem;
  color: #565656;
}

.workWithUsWeb .infoSection:hover .pricePlan .priceCard:hover .priceText {
  color: #F9FAFB;
}

.workWithUsWeb .priceCard .title,
.workWithUsWeb .comparisonTitleTxtMobile {
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  color: #565656;
}

.workWithUsWeb .comparisonTitleTxt {
  min-height: 90px;
}

.workWithUsWeb .comparisonItem,
.workWithUsWeb .comparisonItemMobile .itemTxt {
  font-family: "outfit" !important;
  color: #FFFFFF;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
}

.workWithUsWeb .comparisonItem .infoIcon {
  min-width: 28px;
  min-height: 25px;
  color: #C2C7CC;
  border: 2px solid #C2C7CC;
  border-radius: 50px;
  cursor: help;
}

.workWithUsWeb .comparisonItem {
  min-height: 40px;
  line-height: 42.5px !important;
}

.workWithUsWeb .infoSection {
  position: relative;
  transition: all 0.5s;
  border-radius: 10px;
}
.workWithUsWeb .infoSection:hover {
  background: linear-gradient(270deg, #FFA98C 4.64%, #F89A90 16.64%, #E4719C 41.64%, #C430AF 75.64%, #AD00BD 98.64%);
  /* background-color: #fafcff; */
  color: #fff;
}

.workWithUsWeb .infoSection .infoSectionTitle{
  color: #fff;
}

.workWithUsWeb .infoSection .infoSectionTitle .btn-buy{
  background: linear-gradient(270deg, #FFA98C 4.64%, #F89A90 16.64%, #E4719C 41.64%, #C430AF 75.64%, #AD00BD 98.64%);
  color: #fff;
}

.workWithUsWeb .infoSection .infoSectionTitle .btn-buy:hover{
  background: #fff;
  color: #E75A5A;
}

.workWithUsWeb .infoSection .infoSectionTitleTxt {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  color: #000;
  min-height: 3.5rem;
}

.workWithUsWeb .infoSectionItem {
  min-height: 45px;
  color: #fff;
}

.workWithUsWeb .infoSectionItem:nth-child(even) {
}
.workWithUsWeb:hover .infoSection .infoSectionTitleTxt {
  -webkit-font-smoothing: antialiased;
  font-size: 17px;
  font-weight: 600;
  line-height: 21px;
  color: #fff;
}

.workWithUsWeb:hover .infoSectionItem:nth-child(even) {
  background: transparent;
}


/* ---FeedbackCard--- */
.testmonialsSection{
  background:  url(../assets/wave-line.svg) 0rem 20rem/100% no-repeat,  #091945;
  min-height: 80vh;
}
.testmonialsSection .mainTitle{
  font-size: 2.5rem;
  font-family: "outfit" !important;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 3rem;
}
.testmonialsSection .mainDescription{
  font-family: "Inter" !important;
  font-size: 1.3rem;
  font-weight: 500;
  color: #565656;
  line-height: 2.5rem;
}
.testmonialsSection .imageContainer{
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  position: relative;
}
.testmonialsSection .imageBottom{
  
  grid-column: 4 / span 8;
  grid-row: 1;
  z-index: 1;
  /* background: #E75A5A;
  border-radius: 15px; */
}
.testmonialsSection .imageTop{
  grid-column: 3 / -1;
  grid-row: 1;
  padding-top: 6%;
  padding-left: 4%;
}

.testmonialsSection .avatar{
  display: grid;
  width: 18rem;
  height: 18rem;
  border-radius: 50%;
  background: linear-gradient(270deg, #FFA98C 4.64%, #F89A90 16.64%, #E4719C 41.64%, #C430AF 75.64%, #AD00BD 98.64%);
  place-items: center;
  position: relative;
}

.testmonialsSection .avatar .avatarText{
  font-family: "outfit" !important;
  font-size: 10rem;
  font-weight: 400;
  color: #FFFFFF;
}

.testmonialsSection .commentText{
  font-family: "outfit" !important;
  font-size: 1.1rem;
  font-weight: 400;
  color: #EBEBEB99;
  line-height: 2.2rem;
}

.testmonialsSection .nameText {
  font-family: "outfit" !important;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2rem;
  color: #FFFFFF;
}
.testmonialsSection .designationText {
  font-family: "Inter" !important;
  font-size: 1.3rem;
  font-weight: 400;
  color: #8A8787;
  line-height: 1.5rem;
}
.testmonialsSection .rating {
  unicode-bidi: bidi-override;
  direction: rtl;
  text-align: left;
}

.testmonialsSection .rating>span {
  display: inline-block;
  position: relative;
  width: 1.1em;
}

.testmonialsSection .rating>span:before,
.testmonialsSection .rating>span:before {
  content: "\2605";
  position: absolute;
  color: gold;
}
.testmonialsSection .slick-prev{
  background-image: url('../assets/tm-prev-icon.svg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 2.6rem;
  height: 2.6rem;
  top:25rem;
  left: 73.5rem !important;
  z-index: 2;
}
.testmonialsSection .slick-prev:hover,
.testmonialsSection .slick-prev:focus{
  background: url('../assets/tm-prev-icon.svg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 2.6rem;
  height: 2.6rem;
}
.testmonialsSection .slick-next:hover,
.testmonialsSection .slick-next:focus{
  background: url('../assets/tm-next-icon.svg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 2.6rem;
  height: 2.6rem;
  top: 25rem;
}
.testmonialsSection .slick-prev::before{
  content: " ";
}
.testmonialsSection .slick-next {
  background-image: url('../assets/tm-next-icon.svg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 2.6rem;
  height: 2.6rem;
  top: 25rem;
}

.testmonialsSection .slick-next::before {
  content: " ";
}

.testmonialsSection .slick-slider {
  width: 95% !important;
}

.testmonialsSection .slick-list {
  margin-left: 5rem !important;
}


.accordion-button::after {
  background-image: url("/public/pic/plus.png") !important;
}
.accordion-button:not(.collapsed)::after {
  background-image: url("/public/pic/minus.png") !important;
}
.accordion-item:first-of-type {
  border-radius: 11px !important;
}
.accordion-item:first-of-type .accordion-button {
  background-color: transparent;
}
.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 1rem;
  text-align: left;
  border: 0;
  border-radius: 11px !important;
  overflow-anchor: none;
  background: transparent !important;
}
.accordion-item:not(:first-of-type) {
  border-radius: 11px;
}

.accordion-item:last-of-type {
  border-radius: 11px !important;
}

/* ---FAQ--- */
.faq_main {
  background: url(/public/pic/bg_2.png) center center/cover no-repeat;
  width: calc(100% - 0px);
  border-radius: 120px 120px 0px 0px;
}

.faq_head {
  margin-top: 6rem;
}

.accord {
  margin-left: 12rem;
}

/* ---Footer--- */
.footer_ul {
  text-align: initial;
  margin-left: 9rem;
}

.textarea {
  margin-left: -1rem;
  border-radius: 5px;
  height: 4rem;
}

.email_icon {
  margin-top: 2rem;
}

.footer_btn {
  height: 2.5rem;
}

.footer_icon {
  font-size: 35px;
}

.last_main {
  margin-left: 10rem;
}

.footer_input {
  padding: 0.375rem 1rem !important;
}

.errortextarea {
  color: red;
}

#dashboard {
  display: none;
}

/* ---Media Screen--- */
@media only screen and (max-width: 991px) {
  .feedback_main {
    margin-top: 66rem;
  }
  .footer_ul {
    margin-left: 1rem;
  }
}

@media only screen and (max-width: 768px) {
  .navbar {
    width: 100vw;
  }
  .btn1 {
    width: calc(100% - 100px);
  }

  .newlogo {
    width: 5rem;
  }

  .verify_btn{
    display: inline-grid;
  }
  .work_main {
    flex-direction: column;
    text-align: center;
  }
  .howitworks_col2_text {
    order: 1;
    height: 12rem;
  }

  .howitworks_col2_img {
    order: 2;
  }

  .price_img {
    width: 20rem !important;
  }

  .price_first {
    flex-direction: column;
    text-align: center;
  }

  .price_head {
    margin-top: 2rem;
  }

  .pricePlan .mainTitle{
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .pricePlan .mainDescription{
    font-size: 0.9rem;
  }

  .pricePlan .slick-slider{
    margin-top: 0;
    margin-bottom: 0;
  }

  .pricePlan .slick-prev {
    background-image: url('../assets/prev-icon.svg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 2rem;
    height: 2rem;
    left: 3rem !important;
    top: 97%;
    z-index: 1000;
  }

  .pricePlan .slick-prev:hover,
  .pricePlan .slick-prev:focus {
    background: url('../assets/prev-icon.svg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 2rem;
    height: 2rem;
  }

  .pricePlan .slick-next:hover,
  .pricePlan .slick-next:focus {
    background: url('../assets/next-icon.svg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 2rem;
    height: 2rem;
  }

  .pricePlan .slick-prev::before {
    content: " ";
  }

  .pricePlan .slick-next {
    background-image: url('../assets/next-icon.svg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 2rem;
    height: 2rem;
    right: 2rem;
    top: 97%;
  }

  .pricePlan .slick-next::before {
    content: " ";
  }

  .testmonialsSection .mainTitle{
    font-size: 1.8rem;
    line-height: 3rem;
  }
  .testmonialsSection .mainDescription{
    font-size: 1.1rem;
    line-height: 2rem;
  }
  .testmonialsSection .imageContainer{
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    position: relative;
  }
  .testmonialsSection .imageBottom{
    grid-column: 3 / span 8;
  }
  .testmonialsSection .imageTop{
    grid-column: 2 / 10;
    padding-top: 12%;
    padding-left: 3%;
  }

  .testmonialsSection .avatar{
    width: 15rem;
    height: 15rem;
  }

  .testmonialsSection .commentText{
    font-size: 1.1rem;
    line-height: 2rem;
  }
  .testmonialsSection .slick-prev{
    width: 2rem;
    height: 2rem;
    left:3rem !important;
    top:85%;
    z-index: 1000;
  }
  .testmonialsSection .slick-prev:hover,
  .testmonialsSection .slick-prev:focus{
    width: 2rem;
    height: 2rem;
  }
  .testmonialsSection .slick-next:hover,
  .testmonialsSection .slick-next:focus{
    width: 2rem;
    height: 2rem;
    top: 85%;
  }
  .testmonialsSection .slick-next {
    width: 2rem;
    height: 2rem;
    right:2rem !important;
    top:85%;
    z-index: 1000;
  }

  .testmonialsSection .slick-slider {
    width: 100% !important;
    margin-top: 0 !important;
  }

  .testmonialsSection .slick-list {
    margin-left: 0 !important;
  }
    
  .feedback_main {
    margin-top: 72rem;
  }

  .card_1 {
    flex-direction: column;
    text-align: center;
  }

  .faq_start {
    flex-direction: column;
    text-align: center;
  }
  .accord {
    margin-left: 6rem;
  }
  .footer_main {
    flex-direction: column !important;
    text-align: center;
  }
}

@media only screen and (max-width: 580px) {
  .feedback_main {
    margin-top: 126rem;
  }

  .last_main {
    margin-left: 1rem;
  }

  .slick-slider {
    width: 100% !important;
  }

  .slick-list {
    margin-left: 0rem !important;
  }
}

@media only screen and (max-width: 414px) {
  .pcimg {
    height: 13rem;
  }
  .howitwork {
    margin-top: 17rem;
  }

  .accord {
    margin-left: 3rem;
  }

  .feedback_main {
    margin-top: 140rem;
  }

  .img_4 {
    margin-top: 8rem;
  }
}



/* ..............WELCOME PAGE STYLING........ */

 .sliderContainerLeft .slick-next{
    background-image: url('../assets/tm-next-icon.svg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 2rem;
    top: 12rem;
    right: -2.6rem;
    width: 2rem;
  }

  .sliderContainerLeft .slick-prev{
    background-image: url('../assets/tm-next-icon.svg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 2rem;
    top: 10.2rem;
    width: 2rem;
    transform: rotate(180deg);
    left: -2.5rem;
  }
  .sliderContainerLeft .slick-prev:hover, .sliderContainerLeft .slick-prev:focus{
    background-image: url('../assets/tm-next-icon.svg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 2.1rem;
    top: 10.2rem;
    width: 2.1rem;
    transform: rotate(180deg);
    left: -2.5rem;
  }
  .sliderContainerLeft .slick-next:hover, .sliderContainerLeft .slick-next:focus {
    background-image: url('../assets/tm-next-icon.svg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 2.1rem;
    top: 12rem;
    right: -2.6rem;
    width: 2.1rem;
}
  .sliderContainerLeft .slick-prev:before,
  .sliderContainerLeft .slick-next:before {
  content: '';
} 

.welcomeFooter h3{
  margin: 0;
  padding: 12px 0;
}