.chart-container .chart {
    margin: 0 32px;
}

.chart-container .chart .label {
    font-weight: 500;
    font-size: 1rem;
}

.chart-container .chart .sent {
    color: #0478D6;
}

.chart-container .chart .opened {
    color: #408718;
}

.circular-chart {
    width: 200px;
    height: 200px;
}

.circle-bg {
    fill: none;
    stroke: #eee;
    stroke-width: 3.8;
}

.circle {
    fill: none;
    stroke-width: 2.8;
    stroke-linecap: round;
    animation: progress 1s ease-out forwards;
}

@keyframes progress {
    0% {
        stroke-dasharray: 0 100;
    }
}

.circular-chart.blue .circle {
    stroke: #0E8DF4;
}

.circular-chart.green .circle {
    stroke: #408718;
}

.percentage {
    font-size: .25em;
    text-anchor: middle;
}

.statsTable th, td {
    padding: 8px;
}