.video_container{
    height: 12.5rem;
    margin-top: -19rem;
}


@media only screen and (max-width:400px){

    .video_container {
        height: 8.5rem;
        margin-top: -12.5rem;
    }
}