.cursorPointer{
    cursor: pointer;
}

.documentCsv{
    height: 14rem !important;
}

.uploadBox{
    border: 1px dashed gray;
}

.uploadBox:hover{
    border: 1px dashed #fff;
    background-color: rgb(174, 170, 170);
    cursor: pointer;
    color: aliceblue;
}

.four{
    background-color: aliceblue;
}
.createNewImport{
    background-color: #ff735c;
    color: white;
}
.excelUpload{
    font-size: 1.5rem;
}