.bulk_main{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #091945 !important;
    min-height:  100vh;
}

.mt_6rem{
    margin-top: 6.1rem;
}

.bulk_card{
    background: #434F6F;
    border-radius: 10px;
    width: 60%;
}
.bulk_card .mainTitle{
    font-family: Outfit !important;
    font-size: 1.6rem;
    font-weight: 600;
    color: #FFFFFF;
}

.bulk_card .mainDesc {
    font-family: Outfit !important;
    font-size: 1rem;
    font-weight: 300;
    color: #EBEBEB99;
}

.bulk_card .horizontal-line{
    border: 1px solid #FFFFFF7D
}

.bulk_card .subTitle {
    font-family: Outfit !important;
    font-size: 1.2rem;
    font-weight: 600;
    color: #FFFFFF;
}

.bulk_card .subDesc {
    font-family: Outfit !important;
    font-size: 1rem;
    font-weight: 300;
    color: #EBEBEB99;
}

/* .bulksHover:hover{
    background-color: aliceblue;
} */