
.bgcover{
  background-size: cover;
}
.bgcenter{
  background-position: center;
}
.bgNoRepeat{
  background-repeat: no-repeat;
}
.letterspace{
  letter-spacing: 1.2px;
}
.font12{
  font-size: 12px;
}
.font14{
  font-size: 14px;
}
.homeHero{
    background-image: url('../assets/homeassets/frameHero.png');
    background-size: cover;  
}
.heroTopPadding{
    width: 100%;
    padding: 160px 0 0 0;
}
.gradient-text {
    background: linear-gradient(to right, #bc20b5, #f39093);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
  }

  .heroBtn{
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to right, #bc20b5, #f39093);
    border: none;
    cursor: pointer;
    transition: 400ms;
  }
  .generalBtn{
    border: 2px solid #dedee4;
  }
  .generalBtn:hover {
    border: 2px solid #E3719C;
  }


  .rightHero{
    height: 50vw;
  }
  .mid{
    position: absolute;
    right: -47px;
    top: 0;
    visibility: visible;
    animation: middleFade 3s ease-in-out forwards infinite alternate ;
    transition: opacity 0.5s ease-in-out;
  }
  .lower{
    position: absolute;
    right: 0;
    top: 0;
    visibility: visible;
    animation: lowestfade 3s ease-in-out forwards infinite alternate;
    transition: opacity 0.5s ease-in-out;
  }

  @keyframes middleFade {
    0%{
      pacity: 1;
      visibility: visible;
    }
    50%{
      opacity: .75;
      visibility: visible;
    } 
    75% {
      opacity: .5;
      visibility: visible;
    }
    100% {
      opacity: 0;
      visibility: none;
    }
  }
  
  @keyframes lowestfade {
    0%{
      opacity: 0;
      visibility: none;
    }
    50%{
      opacity: 0.25;
      visibility: visible;
    }
    75% {
      opacity: 0.5;
      visibility: visible;
    }
    100% {
      opacity: 1;
      visibility: visible;
    }
  }


  /* .........EMAIL MARKETING PAGE STYLING.........  */

  .emailMarketingDiv{
    background-image: url('../assets/homeassets/emailMarketingBg.png');
    
  }

  .blueGradient{
    background: linear-gradient(to right, black, blue);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
  }
  .icon-box {
    padding: 20px;
    border-radius: 8px;
  }
  
  .icon-img {
    width: 75px;
    height: 75px;
    object-fit: contain;
  }


  /* .........NEW FAQ MARKETING PAGE STYLING.........  */
  .newFaqSecondSection {
    background-image: url('../assets/homeassets/faqFrame.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 640px;
  }
  
  .newFaqSecondSection .title {
    font-family: "Outfit" !important;
    font-size: 30.4px;
    font-weight: 400;
    line-height: 33.6px;
    letter-spacing: 1px;
  }
  .newAskedQusAccordion {
    border-radius: 10px;
    overflow: hidden;
    box-shadow: none;
  }
  
  .newAskedQusAccordion .accordion-item {
    border-radius: 16px !important;
    border: 2px solid #f2f2f9 !important;
    transition: border-color 0.3s ease;
  }
  .newAskedQusAccordion .accordion-item {
    border-radius: 16px !important;
    border: 2px solid #f2f2f9 !important;
    transition: border-color 0.3s ease;
  }
  
  .newAskedQusAccordion .accordion-item:last-child {
    border-bottom: none !important;
  }
 
  
  .newAskedQusAccordion .accordion-button {
    font-family: "Outfit" !important;
    font-size: 22.4px;
    font-weight: 500;
    line-height: 32px;
    color: black !important;
    border-radius: 0 !important;
    -webkit-font-smoothing: antialiased;
  }
  
  .newAskedQusAccordion .accordion-body p {
    color: #6f6c90;
    font-size: 17.6px;
    font-weight: 400;
    line-height: 32px;
    -webkit-font-smoothing: antialiased;
  }
  
  .newAskedQusAccordion .accordion-button:focus {
    border-color: none !important;
    box-shadow: none !important;
  }
  
  .newAskedQusAccordion .accordion-button::focus-visible {
    outline: none !important;
  }
  
  .newAskedQusAccordion .accordion-body {
    background-color: none;
  }
  
  .accordion-button:not(.collapsed) {
    box-shadow: none !important;
  }
  
  .newAskedQusAccordion .accordion-button::after {
    background-image: url('../assets/homeassets/PlusIcon.png') !important;
    scale: 2;
    background-position: center;
  }
  
  .newAskedQusAccordion .accordion-button:not(.collapsed)::after {
    background-image: url('../assets/homeassets/MinusIcon.png') !important;
    scale: 2;
    background-position: center;
  }
  #newAccordionExample{
    background-color: transparent;
  }
  .shadowContainer {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
  }
  .toolkitPage{
    background-image: url('../assets/revampHomeassets/leadFrame.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .serviceImgDiv{
    background-color: #eef2f9;
  }
  .serviceImgDiv:hover{
    background: linear-gradient(135deg, #cdf2f9, #f8c6d9);
  }

  .serviceBtn {
    background: white !important;
    border: 1px solid #E4719C !important;
    color: #bc20b5 !important;
  }
  
  .serviceBtn:hover {
    background: linear-gradient(to right, #bc20b5, #E3719C) !important;
    color: white !important; 
    border: 1px solid transparent !important;
  }

  .extensionInner{
    background-color: #eef2f9;
    transition: 0.4s;
  }
  .extensionInner:hover{
    background: linear-gradient(135deg, #cdf2f9, #f8c6d9);
  }


/* ..............NEW PRICE PLAN STYLING............... */

.newPricePlan{
  background: url('../assets/revampHomeassets/priceFrame.svg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.newPricePlan .mainTitle {
  font-size: 40px;
  font-family: "outfit" !important;
  font-weight: 400;
  line-height: 64px;
}

.newPricePlan .mainDescription {
  font-family: "outfit" !important;
  font-size: 20.8px;
  font-weight: 500;
  line-height: 32px;
}
.newPricePlan .btn-discove {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 28px;
  gap: 15px;
  background-color: transparent;
  outline: 3px #E75A5A solid;
  outline-offset: -3px;
  border-radius: 10px;
  border-color: #E75A5A;
  cursor: pointer;
  transition: 400ms;
  color: #E75A5A;
  font-family: "Inter";
  font-size: 19.2px;
  font-weight: 600;
}

.newPricePlan .priceCard:hover .btn-discove {
  background-color: #E75A5A;
  color: white;
  border: none;  
}

.newWorkWithUsWeb{
  background-color: white;
  padding: 16px;
  border-radius: 16px;
}
.newWorkWithUsWeb .newPricePlan .priceCard .title {
  font-family: "Inter";
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  color: #191A15;
}
.newWorkWithUsWeb .infoSection:hover .newPricePlan .priceCard .title {
  color: #F9FAFB;
}

.newWorkWithUsWeb .newPricePlan .priceCard .priceText {
  font-family: "Inter";
  font-size: 17.6px;
  font-weight: 500;
  line-height: 32px;
  color: #565656;
}

.newWorkWithUsWeb .infoSection:hover .newPricePlan .priceCard:hover .priceText {
  color: #F9FAFB;
}

@media only screen and (max-width: 768px) {
  .navbar {
    width: 100vw;
  }
  .btn1 {
    width: calc(100% - 100px);
  }

  .newlogo {
    width: 80px;
  }

  .verify_btn{
    display: inline-grid;
  }
  .work_main {
    flex-direction: column;
    text-align: center;
  }
  .howitworks_col2_text {
    order: 1;
    height: 192px;
  }

  .howitworks_col2_img {
    order: 2;
  }

  .price_img {
    width: 320px !important;
  }

  .price_first {
    flex-direction: column;
    text-align: center;
  }

  .price_head {
    margin-top: 32px;
  }

  .newPricePlan .mainTitle{
    font-size: 24px;
    line-height: 32px;
  }
  .newPricePlan .mainDescription{
    font-size: 14.4px;
  }

  .newPricePlan .slick-slider{
    margin-top: 0;
    margin-bottom: 0;
  }

  .newPricePlan .slick-prev {
    background-image: url('../assets/prev-icon.svg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 32px;
    height: 32px;
    left: 48px !important;
    top: 97%;
    z-index: 1000;
  }

  .newPricePlan .slick-prev:hover,
  .newPricePlan .slick-prev:focus {
    background: url('../assets/prev-icon.svg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 32px;
    height: 32px;
  }

  .newPricePlan .slick-next:hover,
  .newPricePlan .slick-next:focus {
    background: url('../assets/next-icon.svg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 32px;
    height: 32px;
  }

  .newPricePlan .slick-prev::before {
    content: " ";
  }

  .newPricePlan .slick-next {
    background-image: url('../assets/next-icon.svg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 32px;
    height: 32px;
    right: 32px;
    top: 97%;
  }

  .newPricePlan .slick-next::before {
    content: " ";
  }

  .testmonialsSection .mainTitle{
    font-size: 28.8px;
    line-height: 48px;
  }
  .testmonialsSection .mainDescription{
    font-size: 17.6px;
    line-height: 32px;
  }
  .testmonialsSection .imageContainer{
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    position: relative;
  }
  .testmonialsSection .imageBottom{
    grid-column: 3 / span 8;
  }
  .testmonialsSection .imageTop{
    grid-column: 2 / 10;
    padding-top: 12%;
    padding-left: 3%;
  }

  .testmonialsSection .avatar{
    width: 240px;
    height: 240px;
  }

  .testmonialsSection .commentText{
    font-size: 17.6px;
    line-height: 32px;
  }
  .testmonialsSection .slick-prev{
    width: 32px;
    height: 32px;
    left:48px !important;
    top:85%;
    z-index: 1000;
  }
  .testmonialsSection .slick-prev:hover,
  .testmonialsSection .slick-prev:focus{
    width: 32px;
    height: 32px;
  }
  .testmonialsSection .slick-next:hover,
  .testmonialsSection .slick-next:focus{
    width: 32px;
    height: 32px;
    top: 85%;
  }
  .testmonialsSection .slick-next {
    width: 32px;
    height: 32px;
    right:32px !important;
    top:85%;
    z-index: 1000;
  }

  .testmonialsSection .slick-slider {
    width: 100% !important;
    margin-top: 0 !important;
  }

  .testmonialsSection .slick-list {
    margin-left: 0 !important;
  }
    
  .feedback_main {
    margin-top: 1152px;
  }

  .card_1 {
    flex-direction: column;
    text-align: center;
  }

  .faq_start {
    flex-direction: column;
    text-align: center;
  }
  .accord {
    margin-left: 96px;
  }
  .footer_main {
    flex-direction: column !important;
    text-align: center;
  }
}

.newWorkWithUsWeb .infoSection .infoSectionTitle{
  color: black;
}

.newWorkWithUsWeb .infoSection .infoSectionTitle .btn-buy{
  background: linear-gradient(270deg, #FFA98C 4.64%, #F89A90 16.64%, #E4719C 41.64%, #C430AF 75.64%, #AD00BD 98.64%);
  color: #fff;
}

.newWorkWithUsWeb .infoSection .infoSectionTitle .btn-buy:hover{
  background: #fff;
  color: #E75A5A;
}

.newWorkWithUsWeb .infoSection .infoSectionTitleTxt {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  color: #000;
  min-height: 56px;
}
.newWorkWithUsWeb .infoSectionItem {
  min-height: 45px;
  color: #707070;
}
.newWorkWithUsWeb .infoSectionItem:nth-child(even) {
}
.newWorkWithUsWeb:hover .infoSection .infoSectionTitleTxt {
  -webkit-font-smoothing: antialiased;
  font-size: 17px;
  font-weight: 600;
  line-height: 21px;
  color: #fff;
}

.newWorkWithUsWeb:hover .infoSectionItem:nth-child(even) {
  background: transparent;
}
.newWorkWithUsWeb .comparisonItem,
.newWorkWithUsWeb .comparisonItemMobile .itemTxt {
  font-family: "outfit" !important;
  color: #FFFFFF;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
}

.newWorkWithUsWeb .comparisonItem .infoIcon {
  min-width: 28px;
  min-height: 25px;
  color: #C2C7CC;
  border: 2px solid #C2C7CC;
  border-radius: 50px;
  cursor: help;
}

.newWorkWithUsWeb .comparisonItem {
  min-height: 40px;
  line-height: 42.5px !important;
}
.pinkGrad{
  background: linear-gradient(to right, #bc20b5, #E3719C);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
}
.tickColor{
  color:#E3719C;
}

/* ...........ELEVATE SECTION STYLING.............  */
.elevateDiv{
  background: url('../assets/revampHomeassets/ElevateFrame.svg');
  background-repeat: no-repeat;
  background-size: cover;
}
.newBuyBtn:hover{
  background-color: #fafcff;
}


/* ...........TESTIMONIALS SECTION STYLING.............  */

.testCard{
  height: 450px;
}
.centerImg{
  left: 0;
  top: 0;
}
.textDiv{
  text-align: center;
  z-index: 5;
  position: absolute;
  top: 25%;
  left:20%;
  width: 50%;
}
.textDiv p{
  line-height:1.3;
}
.textDiv h3,h4{
  line-height:1;
}
.testimonials{
  background-color: #f6f9ff;
}
.slick-prev,.slick-next{
  background-color: #E3719C;
  border-radius: 50%;
}
.slick-prev:hover,.slick-next:hover{
  background-color: #C430AF;

}
.newlist-group li{
  line-height: 1.5;
}
@media only screen and (min-width:1680px) {
  .textDiv{
    top:24%;
    left:13%;
  }
  
}
@media only screen and (max-width:1340px) {
  .textDiv{
    top:18%
  }
  .textDiv p{
    line-height: 1.1;
  }
  .textDiv h3{
    font-size: 16px;
  }
  .textDiv h4{
    font-size: 16px;
  }
}
@media only screen and (max-width:1024px) {
  .textDiv{
    top:23%;
    left:18%;
  }
  .textDiv h3{
    line-height: 0.9;
    margin-bottom: 4px;
  }
  .textDiv h4{
    line-height: 0.9;
    margin-bottom: 4px;
  }
  .textDiv p{
    font-size: 14px;
    line-height: 1;
  }
}
@media only screen and (max-width:870px) {
  .textDiv{
    top:18%;
    left:20%;
  }
  
}
@media only screen and (max-width:768px) {
  .textDiv{
    top:16%;
    left:22%;
  }
  .textDiv p{
    font-size: 14px;
    line-height: 1;
  }
  
}
@media only screen and (max-width:380px) {
  .textDiv{
    top:15%;
    left:22%;
  }
  .textDiv h3{
    font-size: 13px;
    line-height: 0.8;
    margin-bottom: 2px;
  }
  .textDiv h4{
    font-size: 13px;
    line-height: 0.8;
    margin-bottom: 2px;
  }
  .textDiv p{
    font-size: 12px;
  } 
}

.pricingHero{
  background-image:url('../assets/revampHomeassets/PricingFrame.svg');
  background-size: cover;
  background-position: center;
}

/* ................COMPANY PAGE REVAMP ............ */

.letsTalk{
  background-image:url('../assets/about/letsTalkBg.svg');
  background-size: cover;
  background-position: center;
}
.visionDiv{
  background-image:url('../assets/about/VisionBg.svg');
  background-size: cover;
  background-position: center;
}
.vikTeam{
  background-image: url('../assets/about/teamBg.svg');
}
.rockstarDiv{
  background-image: url('../assets/revampHomeassets/ElevateFrame.svg');
}
.companyDiv{
  background-image: url('../assets/about/CompanyBg.svg');
}
.progressDiv{
  background-image: url('../assets/about/accordFrame.svg');
}
.accordion1 {
  width: 20%;
  min-height: 550px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  cursor: pointer;
  transition: all 0.5s ease;
  position: relative;
}

.accordion1.activenow {
  width: 40%;
}

.accordion-header1 {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}

.accordion-content1 {
  margin-top: 20px;
  text-align: left;
  font-size: 14px;
  line-height: 1.6;
}

.accordion-button1 {
  position: absolute;
  bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #007bff;
  font-size: 24px;
}

/* ............PAGE NOT FOUND PAGE CSS............  */
.errorPage{
  background-image: url('../assets/revampHomeassets/errorBg.svg');
}