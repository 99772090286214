.discoverCompany{
  background-color: #434f6f;
}

.mainDiscoverComp{
    display: flex;
}

.companyFilters{
    width: 30%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: 100vh;
}

.companyDisplay{
  background-color: #091945;
    padding-left: 3rem;
    display: flex;
    flex-direction: column;
}

.cursorPointer {
    cursor: pointer;
  }
  .backBtnBox{
    margin-top: 3rem;
  }
  
  .backButton {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3rem;
    width: 7rem;
    border-radius: 1.5rem;
    border: 2px solid #b817b6;
  }
  
  .backText {
    margin: 0;
  }
  .fundingTypes{
    margin-left: 1.5rem;
  }

  .companyDiscovemailLoader {
    border: 10px solid #f3f3f3;
    border-top: 10px solid  #ad00bd;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 1s linear infinite;
    position: absolute;
    top: 40%;
    left: 60%;
    transform: translate(-50%, -50%);
  }
  .displayCompany{
    background-color: #091945;
  }
  .discoveringCategories input, .discoveringCategories select{
    background-color: #434f6f;
  }
  .discoveringCategories select {
    color: white;
    background-color: #434f6f;
  }
  .discoveringCategories select:focus {
    color: black;
    background-color: white;
  }
  .selectSize{

  }
  