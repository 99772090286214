* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.errormsg {
  color: red;
}

.form-control:focus {
  box-shadow: none !important;
}

.contactSection {
  min-height: 100vh;
  padding-top: 90px;
}

.contactSection .loginTitle {
  font-size: 40px;
  font-weight: 800;
  line-height: 48px;
  color: #191a15;
}

.contactSection .loginDescription {
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  color: #565656;
}

.contactSection label {
  font-size: 22px;
  font-weight: 500;
  line-height: 33px;
  color: #191a15;
}

.contactSection .form-control {
  border-radius: 10px;
}

.contactSection .btn-primary {
  background: #e75a5a !important;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  border-radius: 10px !important;
}

.contactSection .loginInfoSection {
  background: linear-gradient(107.05deg,
      rgba(255, 95, 121, 0.3) -147.84%,
      rgba(255, 95, 121, 0.15145) -67.09%,
      rgba(255, 95, 121, 0) 94.55%);
}

.contactSection .loginInfoSection .loginInfoText {
  font-size: 18px;
  font-weight: 400;
  line-height: 33px;
  color: #565656;
}

input :focus {
  border-color: none !important;
  box-shadow: none !important;
}

input :focus:focus-visible {
  outline: none !important;
}

@media only screen and (max-width: 992px) {
  .contactSection {
    padding-top: 4rem !important;
  }

  .contactSection .loginTitle {
    font-size: 32px !important;
  }

  .contactSection .loginDescription {
    font-size: 18px !important;
  }

  .contactSection label {
    font-size: 18px !important;
  }

  .contactSection .btn-primary {
    font-size: 16px !important;
  }

  .contactSection .loginInfoSection .loginInfoText {
    font-size: 16px !important;
  }

}

@media only screen and (max-width: 992px) {
  .contactSection {
    padding-top: 4rem !important;
  }

  .contactSection .loginTitle {
    font-size: 26px !important;
  }

  .contactSection .loginDescription {
    font-size: 16px !important;
  }

  .contactSection label {
    font-size: 16px !important;
  }


  .contactSection .btn-primary {
    font-size: 14px !important;
  }

  .contactSection .loginInfoSection .loginInfoText {
    font-size: 16px !important;
  }

}

@media only screen and (max-width: 768px) {}

@media only screen and (max-width: 400px) {}