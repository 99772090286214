.browser-extenstion__area {
    margin-top: 65px;
}

.browser-extenstion__area .small-text {
    font-size: 14px;
}

.browser-extenstion__area .browser-extenstion__title {
    font-weight: 600;
}

.browser-extenstion__left {
    width: 80%;
    margin: 0 auto;
}

.browser-extension__right {
    position: relative;
}

.browser-extension__right::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    /* height: 100%; */
    background-image: url('../../../assets/browser-extension-preview.svg');
}