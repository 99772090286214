.vl {
border-right: 2px solid #dfe3e7;
height: 100%;
background-color: #f6f9fa;
}


.mt7rem{
margin-top: 6.5rem;
}

.listItems .accordion-item{
background: transparent;
border:none;
}


.listItems .accordion-button:not(.collapsed) {
color: #28323b;
background-color: transparent;
box-shadow: none;
}

.listItems a{
    text-decoration: none;
    color: #6c757d;
}

.listItems .activeLink{
    background-color: #edf0f3 !important;
}

.listItems .accordion-body:hover{
    background-color: #edf0f3;
}

.borderBottom{
    border-bottom: 1px solid gray;
}

 .cursorPointer{
    cursor: pointer;
}

.font13px{
    font-size: 13px;
}
.searchDivs{
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    font-weight: 600;
    padding: 0 1.1rem;
    margin-bottom: 8px;
}
.searchDivs .form-control{
    width: 200px;
    height: 24px;
    font-size: 13px;
    font-weight: 500;
    color: #6c757d;
}
.leadsListSearchDiv{
    color: #6c757d;
}