* {
  margin: 0px;
  padding: 1px;
  /* background: #f9f9f9f0; */
}

.header {
  display: flex;
  align-items: center;
  color: #565454;
}

/* .inpt{
    height: 65px;
    
} */
.inpt input {
  width: 16rem;
  /* margin-left: 35px; */
  padding-left: 25px;
  margin-top: 1.5rem;
  border-radius: 1px;
  height: 30px;
}

.inpt input::placeholder {
  padding-left: 20px;
}

.icon1 {
  position: absolute;
  z-index: 10;
  margin-left: -10px;
  margin-top: 32px;
}

.iconsearch {
  position: absolute;
  z-index: 10;
  margin-left: 6px;
  margin-top: 16px;
}

.btns {
  width: auto;
}
/* .main-head{
    margin-right: 60px;
} */

.home {
  margin-top: 0px;
  margin-inline: 20px;
}

.new {
  font-size: 10px;
}

.new_btn {
  width: 220px;
}

/* .card {
  padding: 8px;
  color: #565454;
  box-shadow: 1px 1px 1px 3px #dad3d3;
} */

.tbhead {
  background-color: #f0f0f0;
  height: 65px;
}
.table {
  width: 155vw !important;
}

td {
  width: 24rem;
  text-align: center;
  vertical-align: middle;
}
tr {
  text-align: center;
}

.table > thead {
  vertical-align: middle;
}
.row1 {
  background: #f8d7da;
  font-weight: 500;
}

.img {
  width: 50px;
  border-radius: 50%;
}

.row2 {
  background: #d1e7dd;
  font-weight: 500;
}

.email {
  font-size: 12px;
}

.search {
  width: 15rem !important;
  padding: 0.5rem 0.5rem 0.5rem 1.5rem !important;
}

.company {
  width: 13vw;
}

.pageno {
  cursor: pointer;
}

.prev {
  height: 2.3rem;
  border: none;
}
.next {
  height: 2.3rem;
  border: none;
}

.errorname {
  color: red;
  margin-top: 3.7rem;
  position: absolute;
  margin-left: -40rem;
}

@media screen and (mim-width: 900px) {
  /* .inpt{
    margin-bottom: 20px;
    height: auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    } */
}

@media screen and (max-width: 1100px) {
  .inpt input {
    width: auto;
  }
}

@media screen and (max-width: 500px) {
  .search {
    display: none;
  }
}
