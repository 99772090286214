/* Campain Main Screen */
.campaign__area {
  padding: 30px 0 0px
}

.campaign__with__google h1, .campaign__with__google h2 {
  color: steelblue;
}

.campaign__with__google h1 span.red-flag {
  color: salmon;
}


.campaign {
  padding-top: 5rem;
  color: #6b737b;
  overflow: hidden;
  text-align: center;
}

.dashboard {
  /* padding-top: 6.5rem; */
  color: #6b737b;
  overflow: hidden;
}

.dashboard__area{
  min-height: 33rem;
}

.dashboard .dashboard__area, .campaign__area {
  display: flex;
  flex-direction: row;
}

.rightside {
  flex: 1;
  background: #eef0f3;
  position: relative;
  color: #787f85;
  padding: 20px 0 0 0;
  border-right: 1px solid #787f851f;
}

.leftside {
  flex: 5;
  padding-left: 10px;
  padding-bottom: 20px;
}

.campaign-header {
  margin: 1.5rem 0px 0;
}

.campaign-header .campaign-header__subtitle {
  font-size: 13px;
}

.dashboard .form-select {
  font-size: 16px;
  color: inherit;
}

.campaign-link {
  color: #E75A5A !important;
}

.dashboard .test-mail-btn {
  color: #E75A5A !important;
  border: 1px solid #dee2e6;
  line-height: 1.7rem;
  border-radius: 3px;
}

.dashboard .test-mail-btn:hover {
  background: transparent;
  border: 1px solid #dee2e6;
}

.campaign-subject .campaign-subject__input {
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  font-size: 16px;
  line-height: 2rem;
  font-weight: 600;
}

.campaign-message .campaign-message__textarea {
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  font-size: 16px;
  line-height: 2rem;
  position: relative;
}

.campaign-message .ql-editor{
  height: 15rem;
  font-size: 16px;
}

.campaign-message .ql-container, .campaign-message .ql-toolbar.ql-snow{
  border-color:#dfe2e6 !important;
}

.rightside .list-group {
  font-size: 14px;
  padding: 0px 5px;
}

.rightside .list-group .list-group-item {
  background-color: transparent;
  border: none;
  padding: 0 12px;
  color: inherit;
  border-radius: 5px;
  line-height: 2.5rem;
}

.rightside .list-group .list-group-item.active {
  background-color: #dfe2e7;
  color: #3a444f;
}

.leftside .campaign-title {
  font-size: 1.4rem;
  text-align: left;
}

.breadcrumb_area {
  background: #eef0f3;
  padding: 23px 0;
}

.campaign-image .campaign-img {
  width: 45rem;
}

.signature-container {
  position: absolute;
  bottom: 5px;
  left: 15px;
  right: 15px;
  max-height: 4.7rem;
  z-index: 1000;
  overflow: auto;
}

.signature_container_remove {
  position: absolute;
  bottom: 60px;
  left: 15px;
  right: 15px;
  max-height: 4.7rem;
  z-index: 1000;
  overflow: auto;
}

.campaignBtnPrimary{
    padding: 10px 20px;
    font-size: 14px;
    color: #fff;
    background: #E75A5A !important;
    border-radius: 5px;
    font-weight: 600;
}

.previewBtns{
  min-width: 8rem;
}

.previewBtns .backBtn{
   font-size: 15px;
    border: 0;
    color: #707880;
    border-bottom: 1px solid #dfe3e8;
}

.customeStepper .indexIcon{
    background: #E75A5A !important;
    height: 24px;
    width: 24px;
    display: block;
    border-radius: 50%;
    color: #fff;
}

.customeStepper .indexTxt{
    font-size: 14px;
    min-width: 24px;
}

.campaignTable{
  max-width: 100%;
  overflow: auto;  
}

.campaignTable thead tr, .campaignTable tbody tr{
  border: none !important;
  border-bottom: 1px solid #ddd !important;
  font-size: 14px;
}

.campaignTable thead tr th{
  text-align: start;
  color: #707880 !important;
}


.campaignTable tbody tr td{
  border: none !important;
  text-align: start;
  color: #707880;
}

.campaignTable thead th{
  background-color: #fff !important;
}

.campaignTable tbody tr .campaignTitle{
  font-size: 15px;
  font-weight: 600;
  white-space: nowrap;
  color:#000;
}

.minHeight73vh{
  min-height: 73vh !important;
}

.minHeight84vh{
  min-height: 84vh !important;
}

.campaign .modal-header{
  border-bottom: none;
}

.campaign .modal-body .hoverColor:hover{
  background-color: aliceblue;
  cursor: pointer;
}

#pagination-first-page{
  display: none;
}

#pagination-last-page{
  display: none;
}


.vl {
  border-right: 2px solid #dfe3e7;
  height: 100vh;
  background-color: #fff;
  }
  
  
  .mt7rem{
  margin-top: 6.5rem;
  }
  
  .listItems .accordion-item{
  background: transparent;
  border:none;
  }
  
  
  .listItems .accordion-button:not(.collapsed) {
  color: #28323b;
  background-color: transparent;
  box-shadow: none;
  }
  
  .listItems a{
      text-decoration: none;
      color: #6c757d;
  }
  
  .listItems .activeLink{
      background-color: #edf0f3 !important;
  }
  
  .listItems .accordion-body:hover{
      background-color: #edf0f3;
  }
  
  .borderBottom{
      border-bottom: 1px solid gray;
  }
  
   .cursorPointer{
      cursor: pointer;
  }
  
  .font13px{
      font-size: 13px;
  }

  .scrollLeft {
      height: none !important;
      max-height: none !important;
      overflow: auto;
  }

  .padding23px{
    padding: 23px !important;
  }
  
  .dashboard .rdt_TableHeadRow {
    background-color: #582B6D !important;
    color: white !important;
    font-weight: bold !important;
  }
  
  .dashboard .sc-dmBjiZ{
    margin-top: 1rem;
    border-radius: 7px !important;
  }

  .followupCheckboxDiv{
    display: flex;
    align-items: center;
  }
  .followUpcheckbox{
    margin-right: 6px;
    height: 14px;
    width: 14px;
  }
  .followupCheckboxDiv label{
    color: #e75a5a !important;
  }
  .pagination {
    list-style: none;
    height: 31.5px;
    width: auto;
    display: flex;
    justify-content:end;
    /* align-items: center; */
    justify-items: center;
    margin-top: 2px;
    cursor: pointer;
    gap: 12px;
    padding-right: 3rem;
    color: grey;
  } 
  .pagination li a{
    text-decoration: none;
    color: #6b737b;
  }
  .active {
    /* background-color: #1e50ff; */
    border: 2px solid #1e50ff;
    border-radius: 50%;
    padding: 0px 10px 0px 10px;
    color: #1e50ff;
  }
  /* .activeHeader{
  border: 1px solid #AD00BD;
  background: linear-gradient(270deg, rgba(255, 169, 140, 0.3) 4.64%, rgba(248, 154, 144, 0.3) 16.64%, rgba(228, 113, 156, 0.3) 41.64%, rgba(196, 48, 175, 0.3) 75.64%, rgba(173, 0, 189, 0.3) 98.64%);
  } */
  .nav-underline .nav-link.activeHeader{
    border-bottom: 4px solid #C430AF !important;
  }
  .nav-underline .nav-link:hover{
    border-bottom: 4px solid #C430AF !important;
  }
  
  .page-item {
    list-style: none;
    padding: 2px 12px;
    height: 31.5px;
    width: 31.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2px;
  }
  .pages{
    display: none;
  }
  .paginationContainer{
    display: flex;
    align-items: baseline;
    justify-content: end;
    gap: 1.5rem;
  }
  .paginationContainer select{
    border: none;
    color: #6b737b;
    font-size: 14px;
    margin-left: 5px;
  }
  
  .followupListBtn{
    color: #fff;
    background-color: #582B6D;
    border: none;
    border-radius: 5px;
    width: 15rem;
    height: 2.5rem;
    margin-bottom: 1rem;
    padding: 0 12px;
    margin-left: -12px;
  }
  .followupSelect{
    width: 15rem;
    height: 2.5rem;
    border-radius: 5px;
    border: 1px solid #c1c1c1;
    margin-bottom: 3.5rem;
    margin-left: -12px;
  }
  .followUpmailsHeader{
    padding: 1rem 0;
    margin: 0 -2.5rem 2.5rem -2.5rem;
    background-color: #EDF0F3;
    padding-left: 2rem;
  }
  .followUpmailsHeader h2{
    margin-bottom: 1.5rem;
  }

  .campaignDetailHeader{
    padding: 0 0 0 2rem;
    margin: 0 -2.5rem 2.5rem -3rem;
    background-color: #EDF0F3;
  }
  .senderAndRemove{
    display: flex;
    justify-content: space-between;
  }
  .senderAndRemove button{
    border: none;
    width: 2.5rem;
    border-radius: 4px;
    height: 25px;
  }
  .followUpStopBtn:hover{
    background-color: #C430AF;
    scale: 1.1;
    color: white;
  }
  .followUpStopBtn{
    margin-left: 6px;
  }




  .audienceTableContainer{
    margin: 0 -2.5rem 2.5rem -3rem;
    /* padding: 0 0 0 2rem; */
  }
  .audienceTable{
    max-width: 100%;
    overflow: auto;  
  }
  
  .audienceTable thead tr, .audienceTable tbody tr{
    border: none !important;
    border-bottom: 1px solid #ddd !important;
    font-size: 14px;
  }
  
  .audienceTable thead tr th{
    text-align: start;
    color: #707880 !important;
    background-color: #EDF0F3 !important;
  }
  
  
  .audienceTable tbody tr td{
    border: none !important;
    text-align: start;
    color: #707880;
  }
  
  .audienceTable thead th{
    background-color: #fff !important;
  }
  
  .audienceTable tbody tr .audienceTable{
    font-size: 15px;
    font-weight: 600;
    white-space: nowrap;
    color:#000;
  }
  .audienceRecipientsHead{
    margin-left: -1.5rem;
  }
  .audienceDwnldBtn{
    margin: 0 0 1.5rem -1.5rem;
  }
  