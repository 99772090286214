
.aboutFirstSection {
  background-color: #13224F;
  min-height: 50vh;
  display: flex;
  align-items: center;
}

.aboutFirstSection .title {
  font-family: "Outfit" !important;
  line-height: 3.1rem;
  font-size: 2.5rem;
  font-weight: 400;
  color: #FFFFFF;
}

.aboutFirstSection .description {
  font-family: "Outfit" !important;
  font-size: 1.1rem;
  font-weight: 400;
  letter-spacing: 0.3px;
  color: #EBEBEB99;
}

.aboutSecondSection .row {
  border-top-left-radius: 53px;
  border-bottom-right-radius: 53px;
  box-shadow: 0px 4px 32px 0px #DDE6ED66;
  background: #142450;
}

.aboutSecondSection .leftSection .title1 {
  font-family: "Outfit" !important;
  font-size: 1.9rem;
  font-weight: 400;
  line-height: 2.1rem;
  letter-spacing: 1px;
  color: #FFFFFF;
}

.aboutSecondSection img {
  border-bottom-right-radius: 53px;
}

.aboutThirdSectionCard {
  background: #434F6F;
  border-radius: 8px;
}

.aboutThirdSection .aboutThirdSectionCard .title{
  font-family: "Outfit" !important;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.5rem;
  color: #FFFFFF;
}

.aboutThirdSection .aboutThirdSectionCard img{
  max-width: 50px;
  object-fit: contain;
  margin-bottom: 10px
}

.aboutThirdSection .title{
  font-family: "Outfit" !important;
  font-size: 2.1rem;
  font-weight: 400;
  line-height: 3rem;
  color: #FFFFFF;
}

.aboutThirdSection .description {
  font-family: "Outfit" !important;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.8rem;
  color: #EBEBEB99;
}

.ourTeamSection .mainHeading{
  font-family: "Outfit" !important;
  font-size: 2.1rem;
  font-weight: 400;
  line-height: 3rem;
  color: #FFFFFF;
}

.ourTeamSection .title1 {
  font-family: "Outfit" !important;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.2rem;
  color: #FFFFFF;
}

.ourTeamSection .title2 {
  font-family: "Outfit" !important;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.2rem;
  color: #FFFFFF;
}

.ourTeamSection .description {
  font-family: "Outfit" !important;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.8rem;
  color: #DFE3E8;
}

.aboutVissionSection .mainHeading {
  font-family: "Outfit" !important;
  font-size: 2.1rem;
  font-weight: 400;
  line-height: 3rem;
  color: #FFFFFF;
}

.aboutVissionSection .mainDescription {
  font-family: "Outfit" !important;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.8rem;
  color: #EBEBEB99;
}

.aboutVissionSection .title {
  font-family: "Outfit" !important;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.2rem;
  color: #FFFFFF;
}
.aboutVissionSection .description {
  font-family: "Outfit" !important;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.8rem;
  color: #DFE3E8;
}
.aboutFamilySection .title {
  font-family: "Outfit" !important;
  font-size: 2.2rem;
  font-weight: 400;
  line-height: 2.5rem;
  color: #FFFFFF;
}

.contactForm .title {
  font-family: "Outfit" !important;
  font-size: 2.1rem;
  font-weight: 600;
  color: #FFFFFF;
}

.contactForm .input-group .form-control {
  color: #A6A6A6;
  width: 100% !important;
}

.contactForm .input-group .form-control::placeholder {
  color: #A6A6A6;
}

.contactForm .input-group-text {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}
