/* .nav_1{
    width: 98%;
    margin-inline: 12px;
}

.chrome:hover{
 color: cornflowerblue !important
}
.logo{
    font-size: 15px !important
} */

/* .secbtn_1{
    margin-left: 120px;
} */

/* .cartbtn{
    height: 40px;
    margin-bottom: 1rem;
} */

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

.dashboard_main {
  background: #E75A5A;
  /* background: url(/public/pic/bg_login.png) center center/cover no-repeat; */
  width: calc(100% - 0px);
  height:auto;
  min-height: 350px;
  border-radius: 0px 0px 90px 90px;
}

.activeNavButton{
  /* border: 1px solid #AD00BD; */
  border-radius: 20px;
  background: linear-gradient(90deg, #BB1EB5 0%, #F39192 100%);
}

.nav-svg {
  width: 24px;       /* Set common width */
  height: 24px;      /* Set common height */
  color: grey;       /* Default color */
  transition: color 0.3s ease;  /* Smooth color change */
}

.activeNavButton .nav-svg {
  color: white;  /* Change color for active nav button */
}

.activeNavButton {
 span {
   color: white;
 }
}


.dashboard_start {
  padding-top: 9rem;
}

.errordash {
  color: #f28c8c;
}

.export_btn {
  color: #E75A5A !important;
  border: 2px solid #E75A5A !important;
}

.export_btn:hover {
  background-color: #E75A5A !important;
  color: white !important;
}

th{
  background: rgba(45, 122, 234, 0.1) !important;
  height: 50px;
}

tr{
  border: 2px solid rgba(45, 122, 234, 0.3) !important;
}

.dropdown-menu{
  margin-top: -11.5rem !important;
  margin-left: 0.5rem !important;
  --bs-dropdown-min-width: 5rem !important;
}

.dropdown-item:hover{
background-color: #c1dbf5 !important;
}

.react-bs-table-sizePerPage-dropdown {
  margin-left: 10px !important;
}
.paddingTop8rem{
  padding-top: 8rem;
}

.marginTop8rem{
  margin-top: 8rem;
}
.dashboard_table{
  min-height: 71.2vh;
  background: #091945;
}

.dashboard_table .sc-lltjXc{
  visibility: hidden !important;
}

.dashboard_table .sc-ilpitK {
  visibility: hidden !important;
}

.dashboard_table .rdt_TableHeadRow {
  background-color: #582B6D !important;
  color: white !important;
  font-weight: bold !important;
}

.dashboard_table .sc-dmBjiZ{
  border-radius: 7px !important;
}
.search-box .form-control {
  background: #13224F;
  color: #FFFFFF;
  border-left: none;
}
.search-box .form-control:focus {
  background: #13224F;
  color: #FFFFFF;
  border-left: none;
}

.search-box .form-control::placeholder {
  color: #A6A6A6;
}

@media only screen and (max-width: 768px) {
  .dashboard_start {
    padding-top: 5rem;
  }
}


