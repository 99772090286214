.profile_main {
  border: 3px solid #e3e3e3;
  border-radius: 4px;
  padding: 20px;
}


.kZqnfX {
    /* transform: translateX(-50%); */
    width: auto;
    min-width: 251px;
    max-width: 100%;
    /* right: 2rem !important; */
    top:4.5rem !important
}

.profile-Card{
  border-radius: 10px !important;
  border: none !important;
}

#circle-avatar {
  display: grid;
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 80px;
  background-color: #ffbd30;
  place-items: center;
  position: relative;
  
}

.profile-sidebar-heading{
  background: linear-gradient(140.09deg, #633BC2 22.77%, #E983AB 131.67%);
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.profile-sidebar-heading .profileName{
  font-size: outfit !important;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.4rem;
  color: #FFFFFF;
}

.profile-sidebar-heading .profileEmail{
  font-size: outfit !important;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.4rem;
  color: #EBEBEB;
}

.profilePic{
  width: 7rem;
  height: 7rem;
  border-radius: 50%;
  /* object-fit: contain; */
}

.profile_firstletter {
  font-size: 1.5rem;
  font-weight: bold;
  color: #FFFFFF;
}

.profile_firstapl {
  font-size: 24px;
  font-weight: bold;
}

.profile_btn{
  border: none;
}
/* ---Edit Profile--- */

.edit_start {
  display: grid;
  place-items: center;
  /* background: url(/public/pic/bg_2.png) center center/cover no-repeat; */
  background-color: #fff3f5 ;
  height: 100vh;
}

#circle {
  display: grid;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #ffbd30;
  place-items: center;
  position: relative;
  
}

.edit_main {
  width: 25rem;
}

#avatar{

  display: grid;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #ffbd30;
  place-items: center;
  position: relative;

}

.edit_error {
  color: red;
  font-weight: 400;
}




.form-control:focus {
  box-shadow: none;
  border-color: #E75A5A;
}

.profile-button {
  /* background: #2f86f0 !important; */
  background-color: #E75A5A !important;
  box-shadow: none ;
}

.profile-button:hover {
  background: red !important;
  color: white !important;
}

.list-group-item-action:hover{
  background-color:#fdfdd1 !important;
}

.profile-button:focus {
  background: #E75A5A !important;
  box-shadow: none;
}

.profile-button:active {
  background: #E75A5A !important;
  box-shadow: none;
}

.back:hover {
  color: #E75A5A !important;
  cursor: pointer;
}

.imageData{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #e3e3e3;

}

.profile_letter{
  line-height: 100px;
  font-size: 50px; 
}



.image_icon{
    position: absolute;
    top: 4.5rem;
    left: 2.2rem;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

#circle .camera {
  display: block !important;
  display: block !important;
    position: absolute;
    right: 1.5rem;
    bottom: 4rem;
}

.profilePic_dropdown{
  border-radius: 50%;
    height: 2.4rem;
    object-fit: cover;
    width: 2.4rem;
}

.profilePic_nav{
  border-radius: 50%;
    height: 3rem;
    width: 3rem;
    object-fit: cover;
}


.progress, .progress-stacked {
  --bs-progress-height: 0.3rem !important;
  --bs-progress-bar-bg: #e75a5a !important;
}