@import url("https://fonts.googleapis.com/css2?family=Familjen+Grotesk:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');


body {
  margin: 0;
  font-family: sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.default270pxHeight {
  max-height: 270px;
  overflow-y: scroll;
}

.default850pxModelHeight {
  max-height: 870px;
  overflow-y: scroll;
}

.btn-discove {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 28px;
  gap: 15px;
  background-color: #E75A5A;
  outline: 3px #E75A5A solid;
  outline-offset: -3px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  transition: 400ms;
  color: white;
  font-family: "Inter";
  font-size: 1.2rem;
  font-weight: 600;
}

.btn-discove:hover {
  background-color: transparent;
  color: #E75A5A;
  border-color: #E75A5A;
}

.btn-disco {
  width: 13rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 40px;
  gap: 8px;
  background: linear-gradient(270deg, #FFA98C 4.64%, #F89A90 16.64%, #E4719C 41.64%, #C430AF 75.64%, #AD00BD 98.64%);
  outline-offset: -3px;
  border-radius: 54px;
  border: none;
  cursor: pointer;
  transition: 400ms;
  color: white;
  font-family: "outfit";
  font-size: 0.9rem;
  font-weight: 700;
  line-height: 1.1rem;
}

/* .btn-disco:hover {
  background: white;
  color: #E75A5A;
  border-color: #E75A5A;
} */

.discove-primary {
  color: #E75A5A;
}

.discove-black {
  color: #000;
}

.discove-secondary {
  color: #4c555e;
}

.btn-discove.btn-icon {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 0 0 0 auto;
  font-size: 16px;
}

.btn-icon .arrow {
  font-size: 22px;
  margin-left: 10px;
}

.align-right {
  color: #737b83;
  font-size: 13px;
  display: block;
  text-align: right;
}

.feature-card__visual {
  position: relative;
}

.feature-card__visual::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  background: url(./assets/pattern-square.svg);
  z-index: -1;
}

.howItWorks__nav li.active {
  background: #DFE3E8;
  color: #191A15;
}
.howItWorks__nav li a {
  text-decoration: none;
  color: #8A8787;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  white-space: nowrap;
}

.howItWorks__nav li.active a {
  color: white;
  text-decoration: none;
  font-weight: 500;
}

.text-btn {
  color: white;
}

.btn:hover .text-btn {
  color: #007acc;
}

.btn-discove-border:hover {
  border: 1px solid #007acc;
}

.btn-animate {
  text-transform: uppercase;
  text-decoration: none;
  color: rgb(255 255 255);
  padding: 10px 30px;
  border: 1px solid;
  border-radius: 1000px;
  display: inline-block;
  transition: all 0.2s;
  position: relative;
  background: #0d6efd;
}

.btn-animate:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(27, 27, 27, 0.5);
}

.btn-animate:active {
  transform: translateY(-3px);
}

.btn-animate::after {
  content: "";
  display: inline-block;
  height: 100%;
  width: 100%;
  border-radius: 100px;
  top: 0;
  left: 0;
  position: absolute;
  z-index: -1;
  transition: all 0.3s;
}

.btn-animate:hover::after {
  background-color: rgb(0, 238, 255);
  transform: scaleX(1.4) scaleY(1.5);
  opacity: 0;
}

.btn-trunkate {
  cursor: pointer;
  font-weight: bold;
}

.termsOfService .termsOfServiceCard{
  /* border: 1px solid #633BC280; */
  border-radius: 20px
}

.termsOfService .horizontalLine {
  border: 1px dashed purple;
  
}

.termsOfService .title {
  font-family: "Outfit" !important;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2rem;
  background: -webkit-linear-gradient(45deg,#b510b8, #fda68d);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.termsOfService .description {
  font-family: "Outfit" !important;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 2rem;
  color: black;
}

.termsOfService .linearGradientTitle{ 
  background: radial-gradient(#FFA98C 40%, #AD00BD 90%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Outfit !important;
  font-size: 2rem;
  font-weight: 400;
  line-height: 2.5rem;
}

/* Responsive For mobiles */

@media screen and (max-width: 767px) {

  /* style changes when the screen gets smaller */
  .btn-discove {
    padding: 6px 18px;
    font-size: 1rem;
  }

  .hero-banner::before {
    left: 0;
  }

  .hero-banner {
    padding-top: 8rem;
  }

  .hero-image-area .introVideo {
    width: 100%;
    height: 50rem;
  }

  .howItWorks__nav {
    /* max-width: 75%;
    flex-direction: column;
    padding: 25px 20px;
    margin-top: -5rem; */
  }

  .howItWorks__nav li:not(:last-child) {
    margin-right: 0;
  }

  .feature-card {
    flex-direction: column;
    max-width: 100%;
  }

  .feature-card__body,
  .feature-card__visual {
    width: 100%;
  }

  .slick-slider {
    margin-top: 11rem;
  }

  .carousel-control-next-icon {
    font-size: 35px;
  }

  .carousel-control-prev-icon {
    font-size: 35px;
  }

  .hero-banner__title {
    font-size: 2.1rem;
  }

  .hero-banner__des {
    margin-bottom: 2rem;
    font-size: 0.9rem;
  }

  .howItWorks__nav li {
    border-radius: 12px;
  }

  .font-50 {
    font-size: 35px;
  }

  .font-45 {
    font-size: 30px;
  }

  .sm-p-2rem {
    padding-bottom: 2rem;
  }

  .feature-card__visual {
    margin-top: 1.5rem;
  }

  .chrome-extenstion-desc {
    width: 90%;
    margin: 1rem auto 0 auto;
  }

  .breadcrumb-area::before {
    content: "";
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    width: 95.5%;
    height: 100%;
    z-index: -1;
    background-color: white;
    border-radius: 10px;
  }

  .logo-area {
    margin-top: 18px;
  }

  .md-padding-80 {
    margin-top: 80px;
  }

  .xs-flex-column {
    flex-direction: column;
  }

  .social-links {
    list-style: none;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

@media screen and (max-width: 1024px) {
  .hero-image-area .introVideo {
    max-width: 100%;
  }

  .howItWorks__nav {
    max-width: 100%;
  }

  .feature-card {
    max-width: 100%;
  }

  .font-50 {
    font-size: 35px;
  }

  .font-45 {
    font-size: 30px;
  }

  .homeBannerImg {
    position: relative;
    left: 0;
    top: 0;
  }

  .navbar-brand img {
    width: 50px;
  }
}

.text-link {
  color: white;
  text-decoration: none;
}

.text-link:hover {
  color: #1660be;
}

.campaign_icon {
  width: 1.5rem;
  height: 1.5rem;
}


.cursorPointer {
  cursor: pointer;
}


.fontInter {
  font-family: "Inter" !important;
}