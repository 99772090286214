* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Inter" !important;
}

.compareSection {
  background: url(/public/pic/compare/compareBanner.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 100% 100%;
  min-height: 75vh;
}


.compareSection .firstText{
  font-family: "Inter" !important;
  line-height: 4rem;
  font-size: 2.5rem;
  font-weight: 500;
  color: #fff;
}

.compareSection .title {
  font-family: "Inter" !important;
  line-height: 4rem;
  font-size: 3rem;
  font-weight: 800;
  color: #fff;
}
.compareSectionTwo .mainTitle {
  font-family: "Inter" !important;
  line-height: 4rem;
  font-size: 2rem;
  font-weight: 800;
  color: #121212;
}
.compareSectionTwo .cards{
  border-radius: 15px;
  background: #fff;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  transition: .2s;
}
.compareSectionTwo .cards:hover{
  transform: scale(1.1);
}
.compareSectionThree{
  background: linear-gradient(107.05deg, rgba(255, 95, 121, 0.3) -147.84%, rgba(255, 95, 121, 0.15145) -67.09%, rgba(255, 95, 121, 0) 94.55%);
}


@media only screen and (max-width: 768px) {
  .compareSection .title {
    line-height: 3rem;
    font-size: 2rem;
  }

  
}