.SubscriptionPage{
  background-color: #fff3f5 !important;
  height: 100%;
  /* min-height: calc(100vh - 98px); */
  /* margin-top: 98px; */
}
.SubscriptionPage .mainTitle{
  font-family: "Inter" !important;
  line-height: 5rem;
  font-size: 1.8rem;
  font-weight: 700;
}
.SubscriptionPage .subTitle{
  font-family: "Inter" !important;
  line-height: 3rem;
  font-size: 1.4rem;
  font-weight: 600;
  border-bottom: 1px solid lightgray;
}
.SubscriptionPage .subTitle2{
  font-family: "Inter" !important;
  line-height: 2rem;
  font-size: 1.1rem;
  font-weight: 600;
}
.SubscriptionPage .txt{
  font-family: "Inter" !important;
  font-size: 1rem;
  font-weight: 500;
  color: #565656;
}