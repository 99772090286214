.buttonContainer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: flex-end;
  }

  .dashboardContainers {
    position: absolute;
    top: 50%;
    left: -20px;
    right: -20px;
    display: flex;
    justify-content: space-between;
    padding: 5px;
  }