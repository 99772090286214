.vl {
    border-right: 2px solid #dfe3e7;
    height: 100vh;
    background-color: #fff;
    }
    
    
    .mt7rem{
    margin-top: 6.5rem;
    }
    
    .listItems .accordion-item{
    background: transparent;
    border:none;
    }
    
    
    .listItems .accordion-button:not(.collapsed) {
    color: #28323b;
    background-color: transparent;
    box-shadow: none;
    }
    
    .listItems a{
        text-decoration: none;
        color: #6c757d;
    }
    
    .listItems .activeLink{
        background-color: #edf0f3 !important;
    }
    
    .listItems .accordion-body:hover{
        background-color: #edf0f3;
    }
    
    .borderBottom{
        border-bottom: 1px solid gray;
    }
    
     .cursorPointer{
        cursor: pointer;
    }
    
    .font13px{
        font-size: 13px;
    }

    .scrollLeft {
        height: none !important;
        max-height: none !important;
        overflow: auto;
    }
    