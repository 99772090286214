.globalTemplatesContainer h6 {
  font-weight: 500;
}

.globalTemplatesContainer ul {
  list-style-type: none;
  padding: 0 !important;
}

.globalTemplatesContainer li {
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  margin: 10px 0;
}

.globalTemplatesContainer li:hover {
  background-color: #eef0f3;
  color: #28323b;
}

.categoryActive {
  background-color: #eef0f3;
  color: #28323b;
}

.subCategoryInActive {
  display: none;
}

.subCategoryListItem {
  padding-left: 20px !important;
}

.templateCount {
  font-weight: 600;
  color: #28323b;
}

.templateCard {
  border: 1px solid #DFE3E8;
  padding: 15px 15px 10px 15px;
  height: 297px !important;
  border-radius: 6px;
  transition: all 0.1s ease-in;
  display: flex;
  flex-direction: column;
  color: inherit;
  position: relative;
}

.templateCard:hover {
  cursor: pointer;
  transform: scale(1.01);
  box-shadow: 0 1px 8px #C7CDD4;
}

.templateSubject {
  font-weight: 600;
  font-size: 1.1rem;
  color: #28323B;
}

.templateBody {
  color: #28323B;
  overflow: hidden;
  margin-bottom: 20px;
}

.templateBody::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 35px;
  left: 0;
  height: 4rem;
  background: linear-gradient(0deg, #FFFFFF 25%, rgba(255, 255, 255, 0) 100%);
}

.templateFilter span {
  background-color: #EEF0F3;
  margin-right: 10px;
  padding: 5px 10px;
  border-radius: 4px;
  color: #28323B;
}

.templateFilter span:hover {
  background-color: #DFE3E8;
}

.cardContainer {
  text-decoration: none;
}

.templateContainer {
  border: 1px solid #DFE3E8;
  color: #212529;
  max-width: 70%;
  margin-right: 50px;
}

.from {
  padding: 20px 30px;
}

.subject {
  border-top: 1px solid #DFE3E8;
  border-bottom: 1px solid #DFE3E8;
  padding: 20px 30px;
}

.body {
  padding: 20px 30px;
}

.templateDetailsContainer button {
  font-weight: 500;
}