.howItWorks__area .tab-container{
  background: #13224F;
  border-radius: 11px;
  min-height: 80vh;
}
.howItWorks__area .tab-container .nav-underline{
  border-bottom: 1px solid #DEDEDE;
  gap: 0;
}
.howItWorks__area .tab-container .nav-underline .border-LR{
  border-left: 1px solid #DEDEDE;
  border-right: 1px solid #DEDEDE;
}
 .howItWorks__area .tab-container .nav-underline .nav-link{
  font-family: "outfit" !important;
  font-size: 1.1rem !important;
  font-weight: 500 !important;
  color: #FFFFFF !important;
  padding-right: 2rem !important;
  padding-left: 2rem !important;
  line-height: 1.5rem;
}
.nav-underline .nav-link.active{
  border-bottom: 4px solid #C430AF !important;
}
.nav-underline .nav-link:hover{
  border-bottom: 4px solid #C430AF !important;
}

.howItWorks__area .mainTitle{
  font-size: 2.5rem;
  font-family: "outfit" !important;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 4rem;
}
.howItWorks__area .mainDescription{
  font-family: "outfit" !important;
  font-size: 1.3rem;
  font-weight: 500;
  color:#EBEBEB99;
  line-height: 2rem;
}
.howItWorks__area .title1{
  font-size: 1.9rem;
  font-family: "outfit" !important;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 2rem;
}
.howItWorks__area .description1{
  font-family: "outfit" !important;
  font-size: 1rem;
  font-weight: 400;
  color: #F9F9F9;
  line-height: 1.5rem;
}


.howItWorks__nav_area {
  /* position: relative;
  border-top: 1px solid #1660be; */
}

.howItWorks__nav {
  overflow: auto;
  list-style: none;
  padding: 10px 0px;
  margin: 8px auto;
  display: flex;
  width: fit-content;
  line-height: 91px;
}

.howItWorks__nav::-webkit-scrollbar{
  display: none !important;
}

.howItWorks__nav li {
  line-height: 50px;
  padding: 0 20px;
  border-radius: 10px;
  /* color: steelblue; */
  color: #8A8787 !important;
  cursor: pointer;
}

.howItWorks__nav li.active {
  background: #DFE3E8;
}

.howItWorks__nav li.active a{
  color: #191A15 !important;
}


.howItWorks__nav li:not(:last-child) {
  /* margin-right: 1rem; */
}

.feature-card {
  /* max-width: 80%;
  margin: 2rem auto 3rem auto;
  padding: 15px 10px;
  background: linear-gradient(145deg, #1660be0d, #3a9e3808);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  display: flex;
  justify-content: center;
  align-items: center; */
}

.feature-card__body {
  width: 40%;
  margin: 0 5rem 0 auto;
}

.feature-card__visual {
  width: 40%;
}

.feature-card__body__title {
  font-size: 53px;
}

.cta__text {
  display: block;
  font-size: 13px;
  color: #333;
  margin-top: 0.5rem;
}


.font-50 {
  font-size: 50px;
}

.font-45 {
  font-size: 45px;
}

.w-100 {
  width: 100%;
}

.w-45 {
  width: 45%;
}

@media only screen and (max-width: 768px){

  .howItWorks__area .mainTitle {
      font-size: 1.75rem;
      line-height: 3rem;
    }
  
    .howItWorks__area .mainDescription {
      font-size: 0.9rem;
      line-height: 1.5rem;
    }
  
    .howItWorks__area .title1 {
      font-size: 1.3rem;
    }
  
    .howItWorks__area .description1 {
      font-size: 1rem;
      min-height: 3rem;
    }

    .howItWorks__area .tab-container .nav-underline .border-LR {
      border-left: none;
      border-right: none;
    }
}