*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* .forpass{
    justify-content: center;
    height: 30rem;
    margin-top: 4.5rem;
}

.pass{
    width: 24rem;
    height: fit-content;
    text-align: center;
}

.newpass {
    width: 15rem;
    height: 2rem;
    padding-left: 15px;
    border-radius: 5px;
    border: 1px solid black;
}

.cpass{
    width: 15rem;
    height: 2rem;
    padding-left: 15px;
    border-radius: 5px;
    border: 1px solid black;
} */

.password_img{
    width: calc(60% - 0px);
    margin-left: 3rem;
    margin-block: 3rem;
}

