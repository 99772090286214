.CampaignReview .card{
    min-height: 15rem;
    width: 20rem;
}

.CampaignReview .card:hover{
    background-color: aliceblue;
}

.CampaignReview .card p{
 margin-bottom: 0;
}

.CampaignReview .card .card-title{
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.CampaignReview .card .card-subtitle{
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.CampaignReview .card .card-text {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
}