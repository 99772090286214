.hero-banner {
  position: relative;
  padding-top: 10rem;
  min-height: 720px;
  background: #091945;
  overflow: hidden;
}

.hero-banner__title {
  font-size: 3rem;
  font-family: "outfit" !important ;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 1.4;
  margin-bottom: 1.5rem;
  letter-spacing: 1px;
}

.hero-banner__des{
  font-family: "outfit" !important ;
  font-size: 1.2rem;
  font-weight: 400;
  color: #EBEBEB99;
}

.hero-area .signLink{
  font-family: "Inter" !important ;
  font-size: 1.2rem !important;
  font-weight: 600 !important;
  color: #565656;
}

.horizontalLine{
  border: 4px solid #E75A5A;
}


.hero-image-area {
  position: relative;
}

.hero-image-area .introVideo {
  position: absolute;
  top: -18rem;
  left: 0;
  width: 45rem;
  height: 50rem;
  z-index: -1;
}

.homeBannerImg {
  position: absolute;
  /* left: 8rem; */
  top: -4rem;
}
.newHeroImg-Box{
  position: relative;
}
.newHeroImg{
  width: 100%;
  position: absolute;
  scale: 1.15;
  left: 100px;
  top: -30px;
}
.gradientColor{
  background: -webkit-linear-gradient(45deg,#b510b8, #fda68d);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media only screen and (max-width: 768px) {
  .horizontalLine {
      border: 3px solid #E75A5A;
    }
}