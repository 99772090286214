.trailscreen {
    width: 100%;
}

.height100vh{
    min-height: 100vh !important;
}

.freqQuestions .mainTitle {
    font-size: 45px;
    font-weight: 800;
    line-height: 71px;
}

.trailscreen .backText {
    font-size: outfit !important;
    font-size: 1rem;
    font-weight: 700;
    color: #FFFFFF;
}

.trailScreenFirstSection {
    background-image: url('../assets/about/CompanyBg.svg');
}

.trailScreenFirstSection .title1 {
    font-family: "Outfit" !important;
    line-height: 3.1rem;
    font-size: 2.5rem;
    font-weight: 400;
    color: #FFFFFF;
}

.trailScreenFirstSection .description {
    font-family: "Outfit" !important;
    font-size: 1.2rem;
    font-weight: 400;
    letter-spacing: 0.3px;
    color: #EBEBEB99;
}

.trailScreenFirstSection .description2 {
    font-family: "Outfit" !important;
    font-size: 1rem;
    font-weight: 400;
    color: #EBEBEB;
}

.trailscreen .mainTitle {
    font-size: outfit !important;
    font-size: 2.1rem;
    font-weight: 400;
    line-height: 3rem;
    color: #FFFFFF;
}

.trailscreen .mainDesc {
    font-size: outfit !important;
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.5rem;
    color: #EBEBEB99;
}

.trailscreen .descriptionTxt {
    font-size: 20px;
    font-weight: 500;
    line-height: 38px;
    color: #565656;

}

.trailscreen .getStartBtn {
    padding: 20px 30px 20px 30px;
    border-radius: 10px;
    background: #E75A5A;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    color: #fff;
}

.trailscreen .emailCards {
    border-radius: 10px;
    box-shadow: 0px 4px 9px 0px #0000000D;
}

.trailscreen .emailCards .emailTxt {
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
    color: #191A15;
}

.trailscreen .emailCards .emailStatus {
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    color: #A6A6A6;
}

.emailValidationToolSection .mainTitle{
    font-family: "Outfit" !important;
    font-size: 2.2rem;
    font-weight: 400;
    line-height: 2.5rem;
    color: #FFFFFF;
}

.emailValidationToolSection .mainDescription {
    font-family: "Outfit" !important;
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 1.5rem;
    color: #EBEBEB99;
}

.emailValidationToolSection .Title {
    font-family: "Outfit" !important;
    font-size: 1.45rem;
    font-weight: 500;
    line-height: 2rem;
    color: #FFFFFF;
}

.emailValidationToolSection .description {
    font-family: "Outfit" !important;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 2rem;
    color: #EBEBEB99;
}

.twoColumnContainer .title {
    font-family: "Outfit" !important;
    line-height: 3rem;
    font-size: 2.2rem;
    font-weight: 400;
    color: #FFFFFF;
}

.twoColumnContainer .subTitle {
    font-family: "Outfit" !important;
    line-height: 2rem;
    font-size: 1.5rem;
    font-weight: 500;
    color: #FFFFFF;
}

.twoColumnContainer .description {
    font-family: "Outfit" !important;
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 2rem;
    color: #EBEBEB99;
}

.trailAreaPartner {
    background-image: url('../assets/product/emailFinderPic5.webp');
    background-repeat: no-repeat;
    min-height: 501px;
}

.trailAreaPartner .title {
    font-family: "Outfit" !important;
    line-height: 3.6rem;
    font-size: 2.5rem;
    font-weight: 700;
    color: #FFFFFF;
}

.trailAreaPartner .buttonColor {
    background: #5F2781 !important;
}

.freqQuestions {
    background: #FDFDFD;

}

.frqAskedQusAccordion {
    border-radius: 10px;
    box-shadow: 0px 4px 31px 0px #5752DA14;
    overflow: hidden;
}

.frqAskedQusAccordion .accordion-item {
    border-radius: 0 !important;
    border: none !important;
    border-bottom: 1px solid #ddd !important;
}

.frqAskedQusAccordion .accordion-item:last-child {
    border-bottom: none !important;
}

.frqAskedQusAccordion .accordion-button {
    font-size: 20px;
    font-weight: 700;
    line-height: 56px;
    color: #191A15 !important;
    border-radius: 0 !important;
    -webkit-font-smoothing: antialiased;
}

.frqAskedQusAccordion .accordion-body p {
    color: #8A8787;
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    -webkit-font-smoothing: antialiased;
}

.frqAskedQusAccordion .accordion-button:focus {
    border-color: none !important;
    box-shadow: none !important;
}

.frqAskedQusAccordion .accordion-button::focus-visible {
    outline: none !important;
}

.frqAskedQusAccordion .accordion-body {
    background-color: none;
}

.accordion-button:not(.collapsed) {
    box-shadow: none !important;
}

.frqAskedQusAccordion .accordion-button::after {
    background-image: url('../../public/pic/trailScreen/plus.svg') !important;
}

.frqAskedQusAccordion .accordion-button:not(.collapsed)::after {
    background-image: url('../../public/pic/trailScreen/minus.svg') !important;
}


.feature-card {
    box-shadow: 0px 0px 22px 0px #FFFFFF30;
    background: #434F6F;
    border-radius: 10px;
}

.feature-card .emailFormText {
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    color: #565656;
}
.feature-card .title{
    font-family: "Outfit" !important;
    font-size: 1.4rem;
    font-weight: 600;
    color: #FFFFFF;
}

.feature-card .subTitle {
    font-family: "Outfit" !important;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    color: #EBEBEB99;
}

.feature-card .input-group .form-control {
    background: #13224F80;
    color: #FFFFFF;
}

.feature-card .input-group .form-control::placeholder {
    color: #A6A6A6;
}

.feature-card .companyName {
    border-left: none !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}

.feature-card .input-group-text {
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
}
.white-card {
    box-shadow: 0px 0px 22px 0px #FFFFFF30;
    background: white;
    border-radius: 10px;
}
.transparentBg{
    background-color: rgba(255, 255, 255, 0.5);
}


.emailVerifyModalPopup .modal-header {
    background: linear-gradient(179.91deg, #633BC2 0.08%, #E983AB 172.84%);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom: none !important;
}

.emailVerifyModalPopup .modal-header .btn-close{
    background-image: url(../assets/modal-close-icon.svg);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    opacity: 1;
    position: absolute;
    top: -4px;
    right: -4px
}

.emailVerifyModalPopup .modal-content {
    border-radius: 20px;
    position: relative;
}

.emailVerifyModalPopup .modal-body {
    background: #434F6F !important;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.emailVerifyModalPopup .emailTitle {
    font-family: "Outfit" !important;
    font-size: 1.55rem;
    font-weight: 400;
    color: #FFFFFF;
}

.emailVerifyModalPopup .statusTitle {
    font-family: "Outfit" !important;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    color:#FFFFFF99;;
}

.emailVerifyModalPopup .statusValid, .emailVerifyModalPopup .statusInValid {
    font-size: 13px;
    line-height: 1.5384615385;
    padding: 3px 10px;
    margin: 0 0 0 8px;
    border-radius: 5px;
}

.emailVerifyModalPopup .statusImg {
    position: absolute;
    top: -0.5rem;
    left: 1.8rem
}

.emailVerifyModalPopup .statusValid {
    color: #078605;
    background-color: #e6fce5;
}

.emailVerifyModalPopup .statusInValid {
    color: #d91f1c;
    background-color: #ffe1e1;
}


.emailVerifyModalPopup .validStatus, .emailVerifyModalPopup .invalidStatus {
    font-size: 14px;
    line-height: 1.5384615385;
    padding: 3px 10px;
    margin: 0 0 0 8px;
    border-radius: 5px;
}

.emailVerifyModalPopup .validStatus {
    color: #078605;
    background-color: #e6fce5;
}

.emailVerifyModalPopup .invalidStatus {
    color: #d91f1c;
    background-color: #ffe1e1;
}

.emailVerifyModalPopup .emailDescriptionTxt {
    font-family: "Outfit" !important;
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 2rem;
    color: #EBEBEB99;
}

.emailVerifyModalPopup .emailTitleTxt {
    font-family: "Outfit" !important;
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 3rem;
    color: #FFFFFF;
}

.emailVerifyModalPopup .domainTextTitle {
    font-family: "Outfit" !important;
    font-size: 1rem;
    font-weight: 400;
    line-height: 2rem;
    color: #EBEBEBd4;
}

.emailVerifyModalPopup .domainTextTitleTwo {
    min-width: 130px;
    font-family: "Outfit" !important;
    font-size: 1rem;
    font-weight: 400;
    line-height: 2rem;
    color: #EBEBEBd4;
}


.emailVerifyModalPopup .domainValidStatus, .emailVerifyModalPopup .domainInValidStatus {
    font-size: 14px;
    line-height: 1.5384615385;
}

.emailVerifyModalPopup .domainValidStatus {
    color: #078605;
}

.emailVerifyModalPopup .domainInValidStatus {
    color: #d91f1c;
}

.emailVerifyModalPopup .domainSubTextTitle {
    font-family: "Outfit" !important;
    font-size: 1rem;
    font-weight: 400;
    line-height: 2rem;
    color: #EBEBEBd4;
}

.emailVerifyModalPopup .btn-close {
    position: absolute;
    top: 13px;
    right: 13px;
}

.emailVerifyModalPopup .officeWebUrl {
    text-decoration: none;
    font-weight: 600;
    font-size: 14px;
    line-height: 1.5384615385;
    color: #E75A5A;
}

.otherProductSection .heading{
    font-family: "Outfit" !important;
    font-size: 2.2rem;
    font-weight: 400;
    color: #FFFFFF;
}

.otherProductSection .productCard{
    background: linear-gradient(179.91deg, rgba(99, 59, 194, 0.5) 0.08%, rgba(233, 131, 171, 0.5) 172.84%);
    border-radius: 30px;
}

.otherProductSection .productCard:hover {
    box-shadow: 0px 4px 10px 0px #DDE6ED66;
}

.otherProductSection .productCard .title{
    font-family: "Outfit" !important;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 2rem;
    color: #FFFFFF;
}

.otherProductSection .productCard .description {
    font-family: "Outfit" !important;
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 2rem;
    color: #FFFFFF;
}


@media (max-width: 992px) {
    .frqAskedQusAccordion .accordion-button {
        font-size: 18px !important;
    }

    .howItWorks__nav li a {
        font-size: 16px !important;
    }

    .frqAskedQusAccordion .accordion-body p {
        font-size: 16px !important;
        line-height: 36px;
    }

    .freqQuestions .mainTitle, .trailscreen .mainTitle {
        font-size: 36px !important;
        line-height: 46px;
    }

    .trailscreen .descriptionTxt {
        font-size: 18px !important;

    }

    .trailscreen .getStartBtn {
        font-size: 16px !important;
    }

    .trailscreen .emailCards .emailTxt {
        font-size: 16px !important;
    }


    

    .feature-card .emailFormText {
        font-size: 14px !important;
    }

    .emailVerifyModalPopup .emailTitle {
        font-size: 20px !important;
    }
}

@media (max-width: 768px) {
    .frqAskedQusAccordion .accordion-button {
        font-size: 16px !important;
    }

    .frqAskedQusAccordion .accordion-body p {
        font-size: 14px !important;
    }

    .freqQuestions .mainTitle, .trailscreen .mainTitle {
        font-size: 28px !important;
    }

    .trailscreen .descriptionTxt {
        font-size: 16px !important;

    }

    .trailscreen .getStartBtn {
        font-size: 14px;
    }

    .trailscreen .emailCards .emailTxt {
        font-size: 14px !important;
    }

    .feature-card .emailFormText {
        font-size: 13px !important;
    }

    .emailVerifyModalPopup .emailTitle {
        font-size: 18px !important;
    }


    .emailVerifyModalPopup .statusTitle {
        font-size: 13px !important;
    }

    .emailVerifyModalPopup .statusValid, .emailVerifyModalPopup .statusInValid {
        font-size: 12px !important;
    }

    .emailVerifyModalPopup .validStatus, .emailVerifyModalPopup .invalidStatus {
        font-size: 13px !important;
    }

    .emailVerifyModalPopup .emailDescriptionTxt {
        font-size: 13px !important;
    }

    .emailVerifyModalPopup .emailTitleTxt {
        font-size: 14px !important;
    }

    .emailVerifyModalPopup .domainTextTitle {
        font-size: 13px !important;
    }

    .emailVerifyModalPopup .domainTextTitleTwo {
        font-size: 13px !important;
    }


    .emailVerifyModalPopup .domainValidStatus, .emailVerifyModalPopup .domainInValidStatus {
        font-size: 13px !important;
    }

    .emailVerifyModalPopup .domainSubTextTitle {
        font-size: 13px !important;
    }

    .emailVerifyModalPopup .officeWebUrl {
        font-size: 13px !important;
    }

}

.productBg1{
    background: url('../assets/product/productBg1.svg');
    background-size: cover;
}
.productBg2{
    background: url('../assets/product/productBg2.svg');
    background-size: cover;
}

.traildark{
    background-color: #091945;
}
.w-90{
    width: 90%;
}
.lstoolText{
    letter-spacing: 1.1px;
}