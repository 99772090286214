.dropdown-toggle::after{
    display: none !important;
}

.dropdown-menu{
    inset: 175px 15px auto auto !important;
}


.email-modal .modal-header{
    background: #15152c;
    color: #fff;
}