.searchCompany {
    background: #13224F;
    border-radius: 20px;
}

.searchCompany .search_title {
    font-size: 2.5rem;
    font-family: "outfit" !important;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 4rem;
}

.searchCompany .search_description {
    font-family: "outfit" !important;
    font-size: 1.2rem;
    font-weight: 400;
    color: #EBEBEB99;
    line-height: 2rem;
}

.coldSection .cold_title{
    font-family: "outfit" !important;
    font-size: 2.5rem;
    font-weight: 400;
    line-height: 3.5rem;
    color: #FFFFFF;
}

.coldSection .cold_description{
    font-family: "outfit" !important;
    font-size: 1.1rem;
    font-weight: 500;
    color: #EBEBEB99;
    line-height: 2.2rem;
}
.coldSection .cold_description2 {
    font-family: "Inter" !important;
    font-size: 1.5rem;
    font-weight: 600;
    color: #565656;
    line-height: 2.6rem;
}

.takeDiscoverMail .title {
    font-family: "outfit" !important;
    font-size: 2.5rem;
    font-weight: 400;
    line-height: 3.5rem;
    color: #FFFFFF;
}

.takeDiscoverMail .description {
    font-family: "outfit" !important;
    font-size: 1.1rem;
    font-weight: 500;
    color: #EBEBEB99;
    line-height: 2.2rem;
}

.scale {
    background-color: aliceblue;
}

.scale_title {
    font-size: 1.525rem;
    line-height: 1.478;
}

.scale_campaigns {
    display: inline-flex;
    align-items: center;
    gap: 0.25em;
    font-size: 1.10rem;
    font-weight: 500;
    line-height: 1.5;
    color: #f4405e;
    background-position: 0 100%;
    background-repeat: no-repeat;
    background-size: 0% 0.15rem;
    transition-duration: 0.25s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s;
    text-decoration: none;
}

.scale_campaigns::after {
    content: "→";
    transform: translateX(0px);
    transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.39, 1.87) 0s;
}

@media only screen and (max-width: 600px) {

    .data_title {
        font-size: 1.438rem;
    }

    .data_description {
        line-height: 1.1;
        font-size: 12px;
        font-weight: 100;
        color: gray;
    }

    .cold_title {
        font-size: 1.438rem;
    }

    .scale_title {
        font-size: 0.7rem;
    }

    .scale_campaigns {
        font-size: 0.6rem;
    }

    small {
        font-size: 0.5rem !important;
    }
}

@media only screen and (max-width: 768px){
    .searchCompany .search_title {
        font-size: 1.5rem;
        line-height: 3rem;
    }
    .searchCompany .search_description {
    font-size: 0.9rem;
    }
    .coldSection .cold_title {
        font-size: 1.5rem;
        line-height: 2.5rem;
    }

    .coldSection .cold_description {
        font-size: 0.9rem;
        line-height: 1.5rem;
    }

    .coldSection .cold_description2 {
        font-size: 1.2rem;
        line-height: 2rem;
    }

    .takeDiscoverMail .title {
        font-size: 1.5rem;
        line-height: 2rem;
    }

    .takeDiscoverMail .description {
        font-size: 0.9rem;
        line-height: 1.5rem;
    }
    
}