* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "outfit" !important;
}

.verifierSection {
  background: #091945 !important;
  min-height: 100vh;
}

.verifierSection .minHeight85 {
  min-height: 85px;
}

.verifierSection .mainTitle {
  font-size: outfit !important;
  font-size: 2.2rem;
  font-weight: 400;
  line-height: 3rem;
  color: #FFFFFF;
}

.verifierSection .mainDescription {
  font-size: outfit !important;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0.3px;
  color: #EBEBEB99;
}
.verifierSection .verifier-card{
  
  background: #434F6F;
  border-radius: 10px;
}

.verifierSection .verifier-card:hover{
  box-shadow: 0px 0px 22px 0px #FFFFFF30;
}

.verifierSection .cardTitle {
  font-family: outfit !important;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 3rem;
  color: #FFFFFF;
}

@media only screen and (max-width: 768px) {
  .verifierSection .title {
    line-height: 3rem;
    font-size: 2rem;
  }

  .verifierSection .description {
    font-size: 1rem;
    font-weight: 500;
  }
}