.table-container {
    padding: 20px;
    /* background-color: #efecec; */
    border-radius: 10px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  }

  .tableMain{
    background-color: #efecec;
  }
  
  .table-title {
    font-size: 16px;
    color: #030229;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .custom-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 10px;
  }

  .custom-table thead {
    background-color: white; /* Change the background color of the header to white */
  }
  
  .custom-table th {
    text-align: left;
    font-weight: 400;
    font-size: 11px;
    padding: 10px;
    color: #030229;
  }
  
  .custom-table td {
    font-size: 12px;
    font-weight: 500;
    padding: 15px 10px;
    background-color: white !important;
    border-bottom: 2px solid white;
    color: #333;
    vertical-align: middle;
    text-align: left;
  }
  
  .custom-table tr {
    /* border-radius: 10px; */
  }
  
  /* .custom-table tr td:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  
  .custom-table tr td:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  } */
  
  .icon-text {
    display: flex;
    align-items: center;
  }
  
  .icon {
    margin-right: 8px;
  }
  
  .view-all-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
  }
  
  .view-all-link {
    color: #007bff;
    text-decoration: none;
    font-weight: 600;
    font-size: 16px;
  }
  
  .view-all-link:hover {
    text-decoration: underline;
  }
  
  .pagination .page-item.active .page-link {
    background-color: white; /* Remove the background color */
    border-color: rgba(24, 144, 255, 1); /* Apply blue border */
    color: rgba(24, 144, 255, 1); /* Change text color to blue */
  }
  
  .pagination .page-link {
    border: 1px solid rgba(128, 128, 128, 0.55);
    color: rgba(128, 128, 128, 0.55);
  }
  .pagination .page-link:focus{
    box-shadow: none;
  }
  
  .pagination .page-link:hover {
    background-color: #e9ecef;
    border-color: blue;
  }