
#megamenu:hover .megamenu__dropdown {
  display: block;
}

#megamenu:hover .megamenu__dropdown_logged {
  display: block;
}

.megamenu__dropdown .megamenu__item {
  display: flex;
  justify-content:space-between;
}

.megamenu__dropdown_logged .megamenu__item {
  display: flex;
  justify-content:space-between;
}

#megamenu{
  position: relative;
}

.megamenu__dropdown {
  list-style: none;
  display: none;
  position: absolute;
  width: 50rem;
  left: -10rem;
  background: transparent;
  padding-top: 2rem;
}

.megamenu__dropdown_logged{
  list-style: none;
  display: none;
  position: absolute;
  left: -2rem;
  width: max-content;
  background: transparent;
  padding-top: 2rem;
}

.megamenu__dropdown .megamenu_item_container{
  background: #ffff;
  box-shadow: 0px 4px 20px 0px #00000014;
  padding: 1rem 2rem;
  border-radius: 13px;
}

.megamenu__dropdown .title {
  font-family: "Inter" !important;
  font-size: 1.3rem;
  color: #191A15;
  font-weight: 600;
  
}
.megamenu__dropdown .more_container{
  background: #FFFDE3;
  min-height: 18rem;
  border-radius: 10px;
}


.megamenu__dropdown_logged .megamenu_item_container{
  background: #ffff;
  box-shadow: 0px 4px 20px 0px #00000014;
  padding: 1rem 2rem;
  border-radius: 13px;
}

.megamenu__dropdown_logged .title {
  font-family: "Inter" !important;
  font-size: 1.3rem;
  color: #191A15;
  font-weight: 600;
  padding-top: 0.6rem;
  
}
.megamenu__dropdown_logged .more_container{
  background: #FFFDE3;
  min-height: 18rem;
  border-radius: 10px;
}

.megamenu__list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.megamenu__list .megamenu__item {
  display: flex;
  transition: all 01s;
  margin-top: 5px;
  margin-bottom: 1rem;
}

.feature__icon {
  margin-right: 1rem;
  width: 50px;
  height: 50px;
  position: relative;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, #633BC2 0%, #E983AB 100%);
}


.megamenu__list .megamenu__item:hover .feature__icon {
  background: #E75A5A33;
}

.feature__icon svg {
  font-size: 30px;
  color: white;
}
.feature__content__icon svg{
  color: #565656;
}

.megamenu__list .megamenu__item:hover span.feature__content__icon svg{
  color: #565656;
  
}

.megamenu__list .megamenu__item:hover svg {
  color: white;
}

.feature__content .discription {
  font-family: "outfit" !important;
  display: block;
  font-weight: 400;
  color: #8A8787;
  font-size: 0.9rem;
}

.feature__content .title {
  font-family: "outfit" !important;
  display: block;
  font-size: 1rem;
  color: #565656;
  font-weight: 500;
}

@media (max-width: 425px) {
  #megamenu:hover .megamenu__dropdown {
    width: 100%;
    top: 11rem;
    padding: 0px 10px;
}

  .megamenu__dropdown {
    left: 0;
  }

  .megamenu__dropdown .megamenu__item {
    flex-direction: row;
  }

  #megamenu:hover .megamenu__dropdown_logged {
    width: 100%;
    top: 11rem;
    padding: 0px 10px;
}

  .megamenu__dropdown_logged {
    left: 0;
  }

  .megamenu__dropdown_logged .megamenu__item {
    flex-direction: row;
  }

  .feature__content span {
    display: block;
    font-weight: normal;
    color: #323436;
    font-size: 12px;
  }
}

@media only screen and (max-width: 991px) {
  .megamenu__dropdown {
    width: 100%;
    left: 0;
    position: static;
    padding: 0;
  }
  .megamenu__dropdown .megamenu_item_container{
    max-height: 50vh;
    overflow: auto;
    padding: 1rem;
  }
  .megamenu__dropdown .megamenu__item{
    display: block;
  }
  .megamenu__list .megamenu__item{
    display: flex;
  }
  .megamenu__dropdown_logged{
    display: none !important;
  }
  .megamenu__dropdown .title{
    padding-top: 0;
  }
}

.text-link {
  text-decoration: none;
  font-weight: normal;
  color: inherit;
}

.navbar-brand {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.navbar-brand span.brand-title {
  font-weight: bold;
  font-size: 23px;
  margin-left: 5px;
}
.nav-pills .nav-link.active{
  border-bottom: none !important;
}

.navbar-brand span {
  font-weight: normal;
}
.feature__content .feature__content__icon, .feature__content .feature__content__text {
  display: inline;
  margin-right: 0.5rem;
}
