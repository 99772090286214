* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.errormsg {
  color: red;
}

/* ----SignIn---- */

.signUpLeftContainer .description{
  font-family: "outfit" !important;
  font-size: 1.1rem;
  font-weight: 600;
  line-height: 3rem;
  color: #DFE3E8;
}

.signUpLeftContainer .mainHeading{
  font-family: "outfit" !important;
  font-size: 2rem;
  font-weight: 600;
  line-height: 3rem;
  color: #FFFFFF;
}

.form-control:focus {
  box-shadow: none !important;
}


.loginSection {
  min-height: 100vh;
  max-height: 100vh;
  overflow: hidden;
}

.loginSection .logo{
  position: absolute;
  top: 3rem;
  left: 3rem;
}

.loginSection .loginTitle {
  font-family: "outfit" !important;
  font-size: 2rem;
  font-weight: 600;
  line-height: 2.5rem;
  color: #FFFFFF;
}

.loginSection .react-tel-input .form-control:focus{
  background: #434F6F;
}

.loginSection .input-group .form-control {
  background: #434F6F;
  color: #FFFFFF;
}

.loginSection .input-group .form-control::placeholder {
  color: #A6A6A6;
}
.loginSection .password {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-right: none !important;
}

.loginSection .login_icon {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  background: #434F6F !important;
  font-size: 2rem;
  color: #A8B5C2;
}
.loginSection .input-group-text:focus{
  border-color: #86b7fe !important;
  outline: 0;
}

.loginSection .orText::before,
.loginSection .orText::after {
  background-color: #A8B5C2;
  content: "";
  display: inline-block;
  height: 2px;
  position: relative;
  vertical-align: middle;
  width: 45%;
}

.loginSection .orText {
  font-size: 20px;
  font-weight: 400;
  line-height: 36px;
  color: #A8B5C2;
}

.loginSection .orText::before {
  right: 10px;
  margin-left: -50%;
}

.loginSection .orText::after {
  left: 10px;
  margin-right: -50%;
}

.loginSection .signUpTxt {
  font-family: "outfit" !important;
  font-size: 1rem;
  font-weight: 500;
  line-height: 36px;
  color: #FFFFFF;
}

.loginSection .signUpTxt span {
  font-weight: 600;
  color: #e75a5a !important;
}
.loginSection .privacyTxt{
  font-family: "outfit" !important;
  font-size: 0.9rem;
  font-weight: 500;
  line-height: 36px;
  color: #FFFFFF;
}
.loginSection .privacyTxt span{
  font-size: 1rem;
  font-weight: 500;
  color: #e75a5a;
}

.loginSection .login_forget span {
  font-family: "outfit" !important;
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 19px;
  color: #FFFFFF;
}

.loginSection .loginInfoSection {
  background: linear-gradient(
    107.05deg,
    rgba(255, 95, 121, 0.3) -147.84%,
    rgba(255, 95, 121, 0.15145) -67.09%,
    rgba(255, 95, 121, 0) 94.55%
  );
}

.loginSection .loginInfoSection .loginInfoText {
  font-size: 18px;
  font-weight: 400;
  line-height: 33px;
  color: #565656;
}

.loginSection .signInNavigation {
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  color: #e75a5a !important;
}

input :focus {
  border-color: none !important;
  box-shadow: none !important;
}

input :focus:focus-visible {
  outline: none !important;
}

@media only screen and (max-width: 992px) {
  .loginSection {
   
  }

  .loginSection .loginTitle {
    font-size: 32px !important;
  }

  .loginSection .loginDescription {
    font-size: 18px !important;
  }

  .loginSection label {
    font-size: 18px !important;
  }


  .loginSection .btn-primary {
    font-size: 16px !important;
  }

  .loginSection .orText {
    font-size: 18px !important;
  }

  .loginSection .signUpTxt {
    font-size: 20px !important;
  }

  .loginSection .login_forget span {
    font-size: 14px !important;
  }

 

  .loginSection .loginInfoSection .loginInfoText {
    font-size: 16px !important;
  }

  .loginSection .signInNavigation {
    font-size: 20px !important;
  }

}

@media only screen and (max-width: 992px) {
  .loginSection {
    
  }

  .loginSection .loginTitle {
    font-size: 26px !important;
  }

  .loginSection .loginDescription {
    font-size: 16px !important;
  }

  .loginSection label {
    font-size: 16px !important;
  }


  .loginSection .btn-primary {
    font-size: 14px !important;
  }

  .loginSection .orText {
    font-size: 16px !important;
  }

  .loginSection .signUpTxt {
    font-size: 18px !important;
  }

  .loginSection .login_forget span {
    font-size: 14px !important;
  }

 

  .loginSection .loginInfoSection .loginInfoText {
    font-size: 16px !important;
  }

  .loginSection .signInNavigation {
    font-size: 18px !important;
  }

}


/* ............REVAMP LOGIN PAGE........... */
.loginRightBg{
  background-color: #F8FCFF;
}
.loginPageBg{
  background-color: #F0F6FA
}
.fs50{
  font-size: 50px;
}
.fs14{
  font-size: 14px;
}
@media only screen and (max-width:992px){
  .fs50{
    font-size: calc(22px + 1.5vw);
  }}
.fw600{
  font-weight: 600;
}
.hexbabfc2{
  color: #babfc2;
}
.hex707070{
  color: #707070;
}
.hex959cbc{
  color: #959cbc;
}
input::placeholder{
  color: #707070;
}
.paddingy12{
  padding: 12px !important;
}
.py11{
  padding: 11px !important;
}

/* ............REVAMP SIGN UP PAGE........... */
.react-tel-input .selected-flag  {
  outline: none;
  position: relative;
  width: 46px !important;
  height: 100%;
  padding: 0 0 0 5px !important;
  border-radius: 3px 0 0 3px;
}