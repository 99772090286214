* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Inter" !important;
}

.OurRecordesSection {
  background: linear-gradient(107.05deg, rgba(255, 95, 121, 0.3) -147.84%, rgba(255, 95, 121, 0.15145) -67.09%, rgba(255, 95, 121, 0) 94.55%);
  min-height: 90vh;
}

.OurRecordesSection .title1 {
  font-family: "Inter" !important;
  line-height: 4rem;
  font-size: 2rem;
  font-weight: 500;
}
.OurRecordesSection .title2 {
  font-family: "Inter" !important;
  line-height: 4rem;
  font-size: 3rem;
  font-weight: 800;
}
.OurRecordesSection .description {
  font-family: "Inter" !important;
  line-height: 2rem;
  font-size: 1.4rem;
  font-weight: 400;
  color: #565656;
}
.twoColumnRecordsSection .title {
  font-family: "Inter" !important;
  font-size: 35px;
  font-weight: 600;
  line-height: 60px;
  color: #121212;
  -webkit-font-smoothing: antialiased;
}

.twoColumnRecordsSection .description {
  -webkit-font-smoothing: antialiased;
  font-size: 18px;
  font-weight: 400;
  line-height: 36px;
  color: #565656;
}

.connectBusinessesSection {
  background: linear-gradient(107.05deg, rgba(255, 95, 121, 0.3) -147.84%, rgba(255, 95, 121, 0.15145) -67.09%, rgba(255, 95, 121, 0) 94.55%);
}

.connectBusinessesSection .mainTitle{
  font-family: Inter !important;
  font-size: 3rem;
  font-weight: 700;
  line-height: 5rem;
}

.connectBusinessesSection .mainDiscription {
  font-family: "Inter" !important;
  line-height: 2rem;
  font-size: 1.4rem;
  font-weight: 400;
  color: #565656;
}

.ourRecordsThirdSection{
  background: #161C28;
  /* min-height: 90vh; */
}

.ourRecordsThirdSection .mainTitle {
  font-family: Inter !important;
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 5rem;
  color: #fff;
}

.ourRecordsThirdSection .mainDiscription {
  font-family: "Inter" !important;
  line-height: 2rem;
  font-size: 1.2rem;
  font-weight: 400;
  color: #fff;
}

.ourRecordsThirdSection .countContainer{
  border-left: 0.4rem solid #E75A5A;
}

.ourRecordsThirdSection .numberText{
  font-family: Inter !important;
  font-size: 4rem;
  font-weight: 700;
  line-height: 4rem;
  color: #fff;
}

.ourRecordsThirdSection .numberDiscription{
  font-family: "Inter" !important;
  line-height: 2rem;
  font-size: 1.1rem;
  font-weight: 400;
  color: #fff;
}



@media only screen and (max-width: 768px) {
  .OurRecordesSection .title2{
    font-size: 2.5rem;
    line-height: 3rem;
  }
  .OurRecordesSection .description{
    font-size: 1.1rem;
  }
.ourRecordsThirdSection .mainTitle{
  line-height: 3rem;
  font-size: 2rem;
}
.connectBusinessesSection .mainTitle{
  font-size: 2rem;
  line-height: 3rem;
}
.connectBusinessesSection .mainDiscription{
  font-size: 1.1rem;
}
.ourRecordsThirdSection .numberText{
  font-size: 3rem;
  line-height: 3rem;
}
.twoColumnRecordsSection .description{
  font-size: 16px;
  line-height: 26px;
}
.twoColumnRecordsSection .title{
  font-size: 28px;
  line-height: 40px;
}
.frqAskedQusAccordion .accordion-button{
  line-height: 25px;
}
}