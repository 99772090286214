.bgBlueHover:hover{
    background-color: aliceblue !important;
}

td{
    background: transparent !important;
}

.campaignSearch{
    width:20rem !important;
}