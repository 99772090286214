* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "outfit" !important;
}

.affiliateFirstSection{
  position: relative;
  padding-top: 10rem;
  min-height: 660px;
  background: #091945;
}

.affiliateFirstSection .title1 {
  font-family: "Outfit" !important;
  line-height: 3.1rem;
  font-size: 2.5rem;
  font-weight: 400;
  color: #FFFFFF;
}

.affiliateFirstSection .description {
  font-family: "Outfit" !important;
  font-size: 1.2rem;
  font-weight: 400;
  letter-spacing: 0.3px;
  color: #EBEBEB99;
}

.affiliateGrowingSection .heading{
  font-family: "Outfit" !important;
  font-size: 2.2rem;
  font-weight: 400;
  letter-spacing: 2px;
  line-height: 1.9rem;
  color: #FFFFFF;
  }

.affiliateGrowingSection .title {
  font-family: "Outfit" !important;
  font-size: 2.5rem;
  font-weight: 800;
  line-height: 3.5rem;
  letter-spacing: 2px;
  background: -webkit-linear-gradient(180deg, #FFA98C 4.64%,#F89A90 16.64%,#E4719C 41.64%,#C430AF 75.64%,#AD00BD 98.64%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.affiliateGrowingSection .description {
  font-family: "Outfit" !important;
  font-size: 1.3rem;
  font-weight: 400;
  color: #FFFFFF;
  letter-spacing: 2px;
  line-height: 2.1rem;
}

.affiliatePartnerTwoColumn .title1 {
  font-family: "Outfit" !important;
  font-size: 2.2rem;
  font-weight: 400;
  line-height: 3rem;
  color: #FFFFFF;
}

.affiliatePartnerTwoColumn .description1 {
  font-family: "Outfit" !important;
  font-size: 1.3rem;
  font-weight: 500;
  color: #EBEBEB99;
  line-height: 2.1rem;
}

.affiliateSecondSection .row {
  border-top-left-radius: 53px;
  border-bottom-right-radius: 53px;
  box-shadow: 0px 4px 32px 0px #DDE6ED66;
  background: #142450;
}

.affiliateSecondSection .leftSection .title1 {
  font-family: "Outfit" !important;
  font-size: 1.9rem;
  font-weight: 400;
  line-height: 2.1rem;
  letter-spacing: 1px;
  color: #FFFFFF;
}

.affiliateSecondSection .leftSection .description {
  font-family: "Outfit" !important;
  font-size: 1.15rem;
  font-weight: 500;
  color: #EBEBEB99;
  line-height: 1.9rem;
}
.affiliateSecondSection img{
  border-bottom-right-radius: 53px;
}

.affiliateThirdSection .title1 {
  font-family: "outfit" !important;
  font-size: 2.5rem;
  font-weight: 400;
  line-height: 3rem;
  color: #FFFFFF;
}

.affiliateThirdSection .description1 {
  font-family: "Outfit" !important;
  font-size: 1.4rem;
  font-weight: 500;
  color: #EBEBEB99;
  line-height: 2.1rem;
}

.affiliateThirdSection .numberText {
  color: white;
  background-color: #E75A5A;
  border-radius: 50%;
  width: fit-content;
  margin: auto;
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
}

.affiliateThirdSection .title2 {
  font-family: "outfit" !important;
  font-size: 1.75rem;
  font-weight: 700;
  line-height: 2.3rem;
  color: #FFFFFF;
}

.affiliateThirdSection .description2 {
  font-family: "Outfit" !important;
  font-size: 1.3rem;
  font-weight: 400;
  color: #959BAD;
  line-height: 2.2rem;
  
}

.affiliateThirdSection .dottedLine{
  border-left: 4px dotted #B1B8C6;

}

.affiliateThirdSection .affiliateImage{
  position: absolute;
  top: 0;
  left: 1rem;
}

.affiliateTestimonialSection .heading {
  font-family: "Outfit" !important;
  font-size: 2.2rem;
  font-weight: 400;
  letter-spacing: 2px;
  line-height: .9rem;
  color: #FFFFFF;
}

.affiliateTestimonialSection .description {
  font-family: "Outfit" !important;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.3rem;
  color: #FFFFFF;
}

.affiliateTestimonialSection .title {
  font-family: "Outfit" !important;
  font-size: 0.8rem;
  font-weight: 600;
  line-height: 1rem;
  color: #FFFFFF;
}

.affiliateTestimonialSection .affiliateCard{
  background: #434F6F;
  box-shadow: 0px 10.850025177001953px 23.085161209106445px 0px #15151526;
  border-radius: 12px;
  min-height: 330px;
}

.affiliateTestimonialSection .affiliateInnerCard {
  background: #6A7491;
  box-shadow: 0px 18px 37.5px 0px #00000040;
  border-radius: 10px;
  
}

.affiliateTestimonialSection .profilePic{
position: absolute;
top: -3rem;
left: 6.5rem;
}

.affiliateTestimonialSection .rating {
  unicode-bidi: bidi-override;
  direction: rtl;
}

.affiliateTestimonialSection .rating>span {
  display: inline-block;
  position: relative;
  width: 1rem;
  font-size: 1.2rem;
}

.affiliateTestimonialSection .rating>span:before,
.affiliateTestimonialSection .rating>span:before {
  content: "\2605";
  position: absolute;
  color: #FBDA09;
}

.affiliateConsultingPartner{
  background-image: url('../../assets/man_with_laptop_banner.svg');
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 501px;
}

.affiliateConsultingPartner .title{
  font-family: "Outfit" !important;
  line-height: 3.6rem;
  font-size: 2.5rem;
  font-weight: 700;
  color: #FFFFFF;
}

.affiliateConsultingPartner .buttonColor{
  background: #5F2781 !important;
}

@media only screen and (max-width: 768px) {
  .affiliateFirstSection .title1 {
    font-size: 2rem;
    line-height: 3rem;
  }

  .affiliateFirstSection .description {
    font-size: 1rem;
  }

  .affiliateSecondSection .row {
    box-shadow: 0px 1px 12px 0px #DDE6ED66;
    
  }

  .affiliateSecondSection .leftSection .title1 {
    font-size: 2rem;
    line-height: 2rem;
  }

  .affiliateSecondSection .leftSection .description {
    font-size: 1rem;
  }

  .affiliateThirdSection .title1 {
    font-size: 2rem;
    line-height: 2rem;
  }

  .affiliateThirdSection .description1 {
    font-size: 1rem;
    font-weight: 400;
  }

  .affiliateThirdSection .title2 {
    font-size: 1.4rem;
  }

  .affiliateThirdSection .description2 {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}