.company_logo img {
    /* text-align: left; */
    margin-bottom: 1rem;
}
.footerSection{
    background-color: #13224F;
}
.newFooterSection{
    background: linear-gradient(45deg, #7e44be, #b06373);
    border-radius: 1.5rem;
}

.footer_main .left-text{
    font-size: 1.06rem;
    font-family: "outfit" !important;
    font-weight: 500;
    color: #A6A6A6;
    line-height: 1.5;
}

.footer_main ul li a{
    font-size: 1.1rem;
    font-family: "outfit" !important;
    font-weight: 500;
    color: #A6A6A6;
    text-decoration: none;
}
.footer_main ul li a:hover{
    color: #FFFFFF;
}
.footer_main .listTitle{
    font-size: 1.1rem;
    font-family: "outfit" !important;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 2.5rem;
}
.footer_main .addressText{
    font-size: 1rem;
    font-family: "outfit" !important;
    font-weight: 500;
    color: #A6A6A6;
    line-height: 1.5;
}

.footer_main .copyRightText{
    font-size: 1.1rem;
    font-family: "outfit" !important;
    font-weight: 500;
    color: #FFFFFF;
}

.legal-actions a {
    color: white;
    text-decoration: none;
    font-size: 1.1rem;
    font-family: "outfit" !important;
    font-weight: 500;
}

.bg-background__footer {
    padding: 30px 0 20px 0;
}

.connect-with-us ul {
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 0;
    margin: 0;
}
.connect-with-us ul li {
    margin-right: 1rem;
    font-size: 18px;
}
.list-item-none {
    align-self: flex-start;
    list-style: none    ;
}
.list-group li {
    line-height: 2.8rem;
}

.input-group .border-radius-3 {
    border-radius: 3px;
}

.list-with-icons {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.contact_us_section {
    list-style: none;
    padding: 0;
    margin: 0;
}

.contact_us_section li {
    text-align: left;
}

.contact_us_section li a {
    color: #fff;
    text-decoration: none;
}

.newsletter-widget .custom_contact_table_look {
    border: none;
}

.custom_contact_table_look th {
    border: none;
    width: 100px;
    vertical-align: text-top;
    background: none !important;
    color: #fff;
}

.custom_contact_table_look td {
    text-align: left;
    vertical-align: text-top;
    padding-bottom: 15px;
}

.custom_contact_table_look tr td a {
    text-decoration: none;
    color: #fff;
}

.custom_contact_table_look td, .custom_contact_table_look tr {
    border: none !important;
}

.title-head-style::before {
    position: relative;
    width: 0px;
    height: 2px;
    background-color: transparent;
    transition: all 0.8s;
}

.title-head-style:hover::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 180px;
    background-color: #fff;
}
.largeHead{
    /* height: 20rem; */
    font-size: 18rem;
    opacity: 0.2;
    font-weight: 350;
}

