.centeredDiv{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
  
.noDataImg{
    width: 20rem;
    height: 20rem;
    object-fit: contain;
}

.lead_table .sc-ilpitK {
    display: none !important;
}

.lead_table .sc-lltjXc{
    display: none !important;
}

.lead_table .rdt_TableHeadRow {
    background-color: #582B6D !important;
    color: white !important;
    font-weight: bold !important;
  }
  
  .lead_table .sc-dmBjiZ{
    border-radius: 7px !important;
  }
  .leadsSearchFilter{
    height: 44px;
  }

  @media only screen and (max-width: 600px) {
    .responsiveButton{
        width: 100%;
    }
  }
  
.lead_table .hZZlND{
  overflow-y: scroll;
}

.lead_table .egKzlE{
overflow: auto;
}