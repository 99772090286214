

/* .........DASHBOARD NEO CSS.........  */
.dashboardNeo{
    background-color: #F2F2F7;
    padding-top: 98px;
  }
  
  .leftContainer{
    width: 75%;
  }
  .rightContainer{
    width: 25%;
  }

.featureCard{
    width: 31.5%;
    margin: 12px 0;
    border: 2px solid #f2f2f7;
    padding: 8px;
    border-radius: 5px;
}
.pathLink{
    text-decoration: none;
    background: linear-gradient(to right, #b716b7, #b86f70); /* Gradient colors */
  -webkit-background-clip: text; /* Clips the background to the text */
  -webkit-text-fill-color: transparent; /* Fills the text with the background */
  background-clip: text;
  color: transparent;
  font-weight: 500;
  font-size: 14px;
}
.captionDiv{
    height: 4.5rem;
    padding-top: 12px;
}
.captionDiv p{
    font-weight: 400;
    font-size: 12px;
    color: #707070;
    margin: 0;
}
.btn-disco {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 40px;
    gap: 8px;
    background: linear-gradient(to right, #b716b7, #ED8C95);
    outline-offset: -3px;
    border: none;
    cursor: pointer;
    transition: 400ms;
    color: white;
    font-family: "outfit";
    font-size: 0.9rem;
    font-weight: 700;
    line-height: 1.1rem;
    width: 260px;
  }

  .chromeExtensionDiv{
    width: 300px;
    margin: auto;
    padding: 1rem;
  }
  .rightInner{
    margin-top: 1.8rem;
  }
  .chromeExtensionDiv p{
    color: #707070;
  }

  .excelSheetDiv{
    width: 300px;
    margin: auto;
    padding: 1rem;
    margin-top: 1.8rem;
  }
  .excelSheetDiv p{
    color: #707070;
  }
  