.contactUsSection {
  background: #161C28;
}

.contactUsSection .titleText {
  color: #fff;
  font-size: 50px;
  font-weight: 600;
  line-height: 61px;
}

.contactUsSection .subTitleText {
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
  color: #A6A6A6;
}

.contactUsSection .formSection {
  border-radius: 20px;
  background: #222938;
}

.contactUsSection .formSection .getStartTxt {
  font-size: 30px;
  font-weight: 500;
  line-height: 36px;
  color: #fff;
}

.contactUsSection .formSection .formLabel {
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  color: #fff;
}

.contactUsSection .formSection input, .contactUsSection .formSection textarea {
  padding: 10px;
  border-radius: 10px;
}

.contactUsSection .formSection .requestMemoBtn {
  padding: 20px 30px 20px 30px;
  border-radius: 10px;
  background: #E75A5A;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  color: #fff;
}

.contactUsSection .formSection .freeTrailTxt {
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  color: #fff;
}

.contactUsSection .formSection .freeTrailTxt .orTxt{
  color: #A6A6A6;
}


@media only screen and (max-width: 992px) {

  .contactUsSection .titleText {
    font-size: 38px !important;
  }

  .contactUsSection .subTitleText {
    font-size: 16px !important;
  }


  .contactUsSection .formSection .getStartTxt {
    font-size: 24px !important;
  }

  .contactUsSection .formSection .formLabel {
    font-size: 16px !important;
  }


  .contactUsSection .formSection .requestMemoBtn {
    font-size: 16px !important;
  }

}

@media only screen and (max-width: 768px) {
  .contactUsSection { 
    padding-right: 15px;
    padding-left: 15px;
  }
  .contactUsSection .titleText {
    font-size: 26px !important;
    line-height: 45px;
  }

  .contactUsSection .subTitleText {
    font-size: 14px !important;
  }


  .contactUsSection .formSection .getStartTxt {
    font-size: 18px !important;
  }

  .contactUsSection .formSection .formLabel {
    font-size: 14px !important;
  }


  .contactUsSection .formSection .requestMemoBtn {
    font-size: 14px !important;
  }

  .contactUsSection .formSection .freeTrailTxt {
    font-size: 12px !important;
  }
}