* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Inter" !important;
}

.claimSection {
  background: linear-gradient(107.05deg, rgba(255, 95, 121, 0.3) -147.84%, rgba(255, 95, 121, 0.15145) -67.09%, rgb(255 95 121 / 9%) 94.55%);
  min-height: 90vh;
}

.claimSection .minHeight85{
  min-height: 85px;
}

.claimSection .title {
  font-family: "Inter" !important;
  line-height: 5rem;
  font-size: 3rem;
  font-weight: 800;
}

.claimSection .description {
  font-family: "Inter" !important;
  font-size: 1.3rem;
  font-weight: 500;
  color: #565656;
}

@media only screen and (max-width: 768px) {
  .claimSection .title {
    line-height: 3rem;
    font-size: 2rem;
  }

  .claimSection .description {
    font-size: 1rem;
    font-weight: 500;
  }
}