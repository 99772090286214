* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.head {
  padding-inline: 8rem;
}

.Privacy_main {
  margin-top: 6rem;
}
