.page {
    /* display: flex; */
    /* height: 100vh; */
  }
  
  .page .headerContainer {
    position: fixed;
    z-index: 100;
    width: 89%;
    -webkit-box-shadow: 0 4px 4px -4px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0 4px 4px -4px rgba(0, 0, 0, 0.25);
    box-shadow: 0 4px 4px -4px rgba(0, 0, 0, 0.25);
  }
  
  .page .pageBody {
    background-color: #fff;
    padding: 0rem 1.5rem 0rem 1.7rem;
    /* min-height: 100vh; */
    /* margin-top: 5rem; */
  }
  
  @media (max-width: 575px) {
    .page .headerContainer {
      width: 100% !important;
    }
  }

  .mt7rem{
    margin-top: 6.6rem !important;
  }

  .scrollLeft{
    height: calc(100vh - 56px);
    max-height: calc(100vh - 14.25vh);
    overflow: auto;
  }

  .scrollRight{
    max-height: calc(100vh - 57.25px);
    overflow: auto;
  }
  