.star{
    color: gold;
}

.card-header{
    background-color: white !important;
}

@media only screen and (max-width: 768px) {
    .feedbackcard{
        flex-direction: column;
        align-items: center;
        
    }

    .card_1{
        width: 20rem !important;
        align-items: center;
    }
}