.overview-company {
  background-color: #f5f6f6;
  padding-top: 90px;
}

.overview-company header {
  position: relative;
  /* background: url("/public/pic/bg_2.png"); */
  background: #fdf1f3;
  z-index: 1;
  width: 100%;
  height: 22rem;
}

.breadcrumb-area::before {
  content: "";
  position: absolute;
  top: 10px;
  left: 15%;
  width: 70%;
  height: 78%;
  z-index: -1;
  background-color: white;
  border-radius: 10px;
}

.logo-area {
  margin-top: 3rem;
  position: relative;
  text-align: center;
}

.breadcrumb-area {
  height: 19rem;
}

.title-overview {
  text-align: left;
  /* margin-top: 35px; */
}

/* .breadcrumb-area .breadcrumb-layout {
  width: 100%;
  height: 275px;
  padding: 30px 25px;
  border-radius: 15px;
} */
.overview-area {
  width: 1182px;
  position: relative;
  margin: 0 auto;
  margin-bottom: 35px;
}

.overview-area__content {
  margin: 0 auto;
  margin-top: -50px;
  display: flex;
}

.w-70 {
  width: 70%;
}

.w-30 {
  width: 30%;
}

.max-text-show {
  width: 250px;
  overflow: hidden;
  white-space: nowrap;
  font-size: 15px;
  text-overflow: ellipsis;
}

.icon-heading {
  font-weight: 600;
}

.margin-right-1rm {
  margin-right: 1rem;
}

.icon-heading svg {
  font-size: 16px;
  color: #ba1916e0;
  margin-right: 8px;
}

.icon-content .badge {
  margin-right: 5px;
  font-size: 13px;
  padding: 6px 12px;
}

.overview-area .social-links {
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 15px;
}

.social-links li {
  margin-right: 1rem;
  font-weight: 600;
}

.social-links img,
.social-links svg {
  width: 30px;
  height: 30px;
  padding: 5px;
  border: 1px solid beige;
  border-radius: 15px;
}

.text-align-left {
  text-align: left !important;
}

#accordionExample {
  background-color: #f5f6f6;
  margin-bottom: 25px;
}

.cutText {
  width: 75%;
  /* position: relative;
  height: 145px;
  overflow-y: scroll; */
}

button.gobackBtn {
  position: absolute;

  top: 13%;
  left: 11%;
}

.rounded-bg {
  padding: 0.5rem;
  border-radius: 10px;
  background: white;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  /* box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px; */
}

.overview-company h3 {
  text-align: left;
  margin-top: 3%;
}

.rounded-bg .social-links {
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.specialities__height {
  max-height: 376px;
  overflow-y: scroll;
}

@media (max-width: 425px) {
  .xs-mx-1rem {
    margin: 0 1rem;
  }
}

@media (max-width: 768px) {
  .overview-area__content {
    display: flex;
    flex-direction: column;
  }

  .overview-card.w-70 {
    width: 100%;
  }

  .overview-card.w-30 {
    width: 100%;
  }

  .breadcrumb-area {
    width: 100%;
  }

  .overview-area {
    width: 100%;
  }

  .breadcrumb-area h3 {
    text-align: center;
  }

  .breadcrumb-area::before {
    left: 3%;
    width: 94%;
    height: 78%;
  }
}

@media (max-width: 1024px) {
  .breadcrumb-area::before {
    left: 3%;
    width: 94%;
  }
}

@media (max-width: 1140px) {
  .breadcrumb-area::before {
    top: 10px;
    left: 4%;
    width: 92%;
    height: 78%;
  }
}

/*  */

.frqAskedQusAccordion {
  border-radius: 10px;
  box-shadow: 0px 4px 31px 0px #5752DA14;
  overflow: hidden;
}

.frqAskedQusAccordion .accordion-item {
  border-radius: 0 !important;
  border: none !important;
  border-bottom: 1px solid #ddd !important;
}

.frqAskedQusAccordion .accordion-item:last-child {
  border-bottom: none !important;
}

.frqAskedQusAccordion .accordion-button {
  font-size: 18px;
  font-weight: 600;
  line-height: 56px;
  color: #191A15 !important;
  border-radius: 0 !important;
  -webkit-font-smoothing: antialiased;
}

.frqAskedQusAccordion .accordion-body p {
  color: #8A8787;
  font-size: 16px;
  font-weight: 400;
  line-height: 47px;
  -webkit-font-smoothing: antialiased;
}

.frqAskedQusAccordion .accordion-button:focus {
  border-color: none !important;
  box-shadow: none !important;
}

.frqAskedQusAccordion .accordion-button::focus-visible {
  outline: none !important;
}

.frqAskedQusAccordion .accordion-body {
  background-color: none;
}

.accordion-button:not(.collapsed) {
  box-shadow: none !important;
}

.frqAskedQusAccordion .accordion-button::after {
  background-image: url('../../../public/pic/trailScreen/plus.svg') !important;
}

.frqAskedQusAccordion .accordion-button:not(.collapsed)::after {
  background-image: url('../../../public/pic/trailScreen/minus.svg') !important;
}

.aboutCompanySection {
  min-height: 350px;
  background: linear-gradient(107.05deg, rgba(255, 95, 121, 0.3) -147.84%, rgba(255, 95, 121, 0.15145) -67.09%, rgba(255, 95, 121, 0) 94.55%);
}

.companyLogoImg {
  object-fit: contain;
}

.companyDetailSection .list-group-item {
  border-color: #eee !important;
}

.companyDetailSection {
  margin-top: -1.5rem !important;
}

.icon-content a {
  color: #E75A5A !important;
}

.companyDetailSection .text-bg-primary {
  background-color: #E75A5A !important;
}

@media (max-width: 992px) {
  .companyDetailSection {
    margin-top: 1.5rem !important;
  }

  .cutText {
    width: 100% !important;
  }

  .frqAskedQusAccordion .accordion-button {
    font-size: 18px !important;
  }

  .frqAskedQusAccordion .accordion-body p {
    font-size: 16px !important;
    line-height: 36px;
  }
}

@media (max-width: 768px) {
  .frqAskedQusAccordion .accordion-button {
    font-size: 16px !important;
  }

  .frqAskedQusAccordion .accordion-body p {
    font-size: 14px !important;
  }
}

/*  */