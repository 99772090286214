
.datePickerStyle {
  height: 45px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid var(--Stroke, #e8e8e8);
  background: #fff;
  .react-daterange-picker__wrapper {
    border: 0px;
    padding: 0px 10px;
    :focus-visible{
      outline: none;
    }
    .react-daterange-picker__calendar-button {
      padding-right: 0px;
    }
  }
  .react-daterange-picker__inputGroup {
    pointer-events: cursor;
  }
  .react-daterange-picker__inputGroup__input:invalid {
    background: white !important;
  }
  .react-daterange-picker__inputGroup
    select.react-daterange-picker__inputGroup__month {
    appearance: none;
  }

  &.inActiveDatePickerStyle {
    .react-daterange-picker__wrapper {
      .react-daterange-picker__inputGroup {
        select {
          color: #807c87;
        }
      }
    }
  }
}

.customDateRangePicker {
  .react-daterange-picker {
    height: 65px;
    width: 100%;
    .react-daterange-picker__wrapper {
      justify-content: flex-start;
      padding: 0px 9px 0px 20px;
      .react-daterange-picker__range-divider {
        padding: 0px 15px;
      }

      .react-daterange-picker__inputGroup:first-child {
        flex-grow: 0;
      }
    }
  }
}
