.area {
  width: 100vw;
  height: 100vh;
  position: relative;
}

.inline-loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid  #ad00bd;
  border-radius: 50%;
  width: 34px;
  height: 34px;
  animation: spin 1s linear infinite;
}

.loader {
    border: 10px solid #f3f3f3;
    border-top: 10px solid  #ad00bd;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 1s linear infinite;

    position: absolute;
    top: 40%;
    left: 46%;
    transform: translate(-50%, -50%);
  }


  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }