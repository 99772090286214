.area-spinner {
    position: relative;
    top: 30%;
    left: 0%;
    transform: translate(-20%, -24%);
}

.customeAvator{
    height: 60px;
    width: 60px;
    border-radius: 50px;
    background: #fff;
    font-size: 24px;
    font-weight: 600;
    color: #E75A5A;
}

.emailInfoTextTitle{
    font-size: 20px;
    font-weight: 600;
    text-transform: capitalize;
    color: #444;
}
.emailInfoTextSubTitle{
    font-size: 16px;
    color: #999;
}

.statusTag{
    margin: 0 !important;
    width: fit-content !important;
}