* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "outfit" !important;
}

.faqFirstSection {
  background-color: #13224F;
}

.faqFirstSection .title1 {
  font-family: "Outfit" !important;
  line-height: 3.1rem;
  font-size: 2.5rem;
  font-weight: 400;
  color: #FFFFFF;
}

.faqFirstSection .description {
  font-family: "Outfit" !important;
  font-size: 1.1rem;
  font-weight: 400;
  letter-spacing: 0.3px;
  color: #EBEBEB99;
}

.faqSecondSection {
  background: #091945;
  min-height: 40rem;
}

.faqSecondSection .title {
  font-family: "Outfit" !important;
  font-size: 1.9rem;
  font-weight: 400;
  line-height: 2.1rem;
  letter-spacing: 1px;
  color: #FFFFFF;
}

#accordionExample{
  background-color: #091945;
}

.frqAskedQusAccordion {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: none;
}

.frqAskedQusAccordion .accordion-item {
  border-radius: 16px !important;
  border: none !important;
  background-color: #132350;
}

.frqAskedQusAccordion .accordion-item:last-child {
  border-bottom: none !important;
}

.frqAskedQusAccordion .accordion-button {
  font-family: "Outfit" !important;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 2rem;
  color: #DFE3E8 !important;
  border-radius: 0 !important;
  -webkit-font-smoothing: antialiased;
}

.frqAskedQusAccordion .accordion-body p {
  color: #EBEBEB99;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 2rem;
  -webkit-font-smoothing: antialiased;
}

.frqAskedQusAccordion .accordion-button:focus {
  border-color: none !important;
  box-shadow: none !important;
}

.frqAskedQusAccordion .accordion-button::focus-visible {
  outline: none !important;
}

.frqAskedQusAccordion .accordion-body {
  background-color: none;
}

.accordion-button:not(.collapsed) {
  box-shadow: none !important;
}

.frqAskedQusAccordion .accordion-button::after {
  background-image: url('../../assets/accordion-plus.svg') !important;
  scale: 2;
  background-position: center;
}

.frqAskedQusAccordion .accordion-button:not(.collapsed)::after {
  background-image: url('../../assets/accordion-minus.svg') !important;
  scale: 1;
  background-position: center;
}

@media only screen and (max-width: 768px) {
  .faqFirstSection .title1 {
    font-size: 2rem;
    line-height: 3rem;
  }

  .faqFirstSection .description {
    font-size: 1rem;
  }

  
}