.construction-area {
  width: 100%;
  height: 100vh;
  background: url("../../assets/underconstruction.jpg") no-repeat center center;
  position: relative;
}

.construction-area::after {
  content: "";
  background: linear-gradient(to top right, #d2b48c, #000000);
  opacity: 0.9;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.construction-title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
  text-align: center;
}

.construction-main-title {
  background-color: #fff;
  color: #d2b48c;
  display: inline-block;
  padding: 15px 35px;
  border-radius: 15px;
}

.construction__sub_title {
  font-size: 3rem;
  color: white;
}
